import { LocalizationModule } from '@abp/ng.core';
import { AsyncPipe, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ExternalLoginFacade, LocalLoginFacade, RegisterFacade } from '@ralba-ng/core/account/domain';
import { ExternalLoginComponent } from '@ralba-ng/core/account/feature-external-login';
import { LocalLoginComponent } from '@ralba-ng/core/account/feature-local-login';

@Component({
    standalone: true,
    selector: 'auth-shell-login-page',
    imports: [LocalizationModule, LocalLoginComponent, RouterLink, ExternalLoginComponent, NgIf, AsyncPipe],
    template: `
        <h1 class="aui-font-bold-20 pb-2">
            {{ 'AppthorAccount::Login' | abpLocalization }}
        </h1>

        <div>
            <core-account-local-login />
        </div>
        <div>
            <ng-container *ngIf="externalLoginFacade.isExternalProviderAvailable$ | async">
                <h2
                    class="text-xl font-bold leading-tight tracking-tight"
                    *ngIf="localLoginFacade.isLocalLoginEnabled$ | async"
                >
                    {{ 'AppthorAccount::OrLoginWith' | abpLocalization }}
                </h2>
                <core-account-external-login />
            </ng-container>
        </div>

        <p class="text-sm " *ngIf="registerFacade.IsSelfRegistrationEnabled$ | async">
            {{ 'AppthorAccount::AreYouANewUser' | abpLocalization }}
            <a
                [routerLink]="['/', 'register']"
                queryParamsHandling="merge"
                class="font-medium text-primary-600 hover:underline dark:text-primary-500"
                >{{ 'AppthorAccount::Register' | abpLocalization }}</a
            >
        </p>
    `,
})
export class LoginPageComponent {
    public readonly externalLoginFacade = inject(ExternalLoginFacade);
    public readonly localLoginFacade = inject(LocalLoginFacade);
    public readonly registerFacade = inject(RegisterFacade);
}
