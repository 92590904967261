import { isNullOrUndefined } from '@abp/ng.core';
import { CommonModule, NgTemplateOutlet } from '@angular/common';
import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component,
    computed,
    ContentChild,
    inject,
    input,
    TemplateRef,
    ViewEncapsulation,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AuiIconComponent } from '@ral/ui:aui/icons';
import { AuiInputComponent } from '@ral/ui:aui/input';
import { AuiColor, AuiOption } from '@ral/ui:aui/utils';
import { injectAuiTranslationDictionary } from '@ral/utils:core/ui';
import { ApplyPipe } from 'ngxtension/call-apply';
import { NgxControlValueAccessor } from 'ngxtension/control-value-accessor';
import { hostBinding } from 'ngxtension/host-binding';

@Component({
    selector: 'aui-list-box',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [ReactiveFormsModule, AuiIconComponent, CommonModule, ApplyPipe, AuiInputComponent, NgTemplateOutlet],
    hostDirectives: [{ directive: NgxControlValueAccessor, inputs: ['value'], outputs: ['valueChange'] }],
    template: `
        <div
            class="bg-surface-background-lighter overflow-y-auto w-full max-h-[150px] border border-surface-background-darken rounded-lg py-2 flex flex-col gap-1 min-w-64"
        >
            @if (filtering()) {
                <div class="w-full flex justify-center pb-1 px-2">
                    <aui-input type="text" [placeholder]="filterPlaceholder" (valueChange)="filter($event)" />
                </div>
            }
            @if (filteredOptions().length > 0) {
                @for (option of filteredOptions(); track option.value) {
                    <div
                        class="w-full py-1 px-3 cursor-pointer hover:bg-surface-background"
                        (click)="select(option.value)"
                        data-testid="option"
                    >
                        <div
                            class="flex flex-nowrap justify-between items-center text-{{
                                option.color || defaultBadgeColor()
                            }}"
                        >
                            <div class="flex-grow min-w-0 shrink text-ellipsis overflow-hidden">
                                <div class="flex-nowrap gap-1 flex">
                                    @if (option.icon; as icon) {
                                        <aui-icon class="pr-2" [icon]="icon"></aui-icon>
                                    }
                                    <span>{{ option.label }}</span>
                                </div>
                            </div>
                            @if (isSelected | apply: cva.value$() : option.value) {
                                <div class="flex-none">
                                    <aui-icon data-testid="check" [icon]="'check'"></aui-icon>
                                </div>
                            }
                        </div>
                    </div>
                }
            } @else {
                <ng-container *ngTemplateOutlet="emptyTemp || defaultEmpty"></ng-container>
                <ng-template #defaultEmpty
                    ><span class="pl-3">{{ emptyLabel() }}</span></ng-template
                >
            }
        </div>
    `,
})
export class AuiListBoxComponent implements AfterContentInit {
    cva = inject<NgxControlValueAccessor<AuiOption['value'][] | AuiOption['value'] | null>>(NgxControlValueAccessor);
    translations = injectAuiTranslationDictionary();
    filterPlaceholder = this.translations.filterPlaceholder();

    allowNull = input<boolean>(true);
    multiselect = input<boolean>(false);
    options = input<AuiOption[]>([]);
    filtering = input<boolean>(false);
    filteredOptions = computed(() => this.options());
    readonly = input<boolean>(false);
    defaultBadgeColor = input<AuiColor>('gray');
    emptyLabel = input<string>(this.translations.noItems());

    @ContentChild('emptyTemplate', { read: TemplateRef }) emptyTemp?: TemplateRef<unknown>;

    ngAfterContentInit(): void {
        console.log(this.emptyTemp);
    }

    select(value: AuiOption['value']) {
        if (this.cva.disabled || this.readonly()) {
            return;
        }
        if (this.multiselect() && Array.isArray(this.cva.value)) {
            if (!this.cva.value?.includes(value)) {
                this.cva.value = [...this.cva.value, value];
            } else {
                if (this.allowNull() || this.cva.value.length > 1)
                    this.cva.value = this.cva.value.filter((p) => p !== value);
            }
        } else {
            if (value !== this.cva.value) {
                this.cva.value = value;
            } else if (this.allowNull()) {
                this.cva.value = null;
            }
        }
    }

    isSelected = (selectedValue: AuiOption['value'] | AuiOption['value'][] | null, item: AuiOption['value']) => {
        if (isNullOrUndefined(item)) {
            return false;
        }
        if (Array.isArray(selectedValue)) {
            return selectedValue.includes(item);
        } else {
            return selectedValue === item;
        }
    };

    filter(value: string) {
        if (value) {
            const filteredOptions = this.options()?.filter((p) => p.label.toLowerCase().includes(value.toLowerCase()));
            this.filteredOptions = computed(() => filteredOptions || this.options());
        } else {
            this.filteredOptions = computed(() => this.options());
        }
    }

    classes = hostBinding(
        'attr.class',
        computed(() => {
            const tailwindClasses = ['aui-font-regular-14', 'transition-all', 'max-w-min'];
            return tailwindClasses.join(' ');
        })
    );
}
