import { LocalizationModule } from '@abp/ng.core';
import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuiButtonComponent } from '@ral/ui:aui/button';
import { AuiFormFieldComponent, AuiFormFieldLabelComponent, AuiFormLayoutComponent } from '@ral/ui:aui/form-layout';
import { AuiInputComponent } from '@ral/ui:aui/input';
import { EMAIL_ADDRESS_PLACEHOLDER, ResetPasswordFacade } from '@ralba-ng/core/account/domain';

@Component({
    standalone: true,
    selector: 'core-account-forgot-password',
    imports: [
        ReactiveFormsModule,
        LocalizationModule,
        AuiButtonComponent,
        AuiFormLayoutComponent,
        AuiFormFieldLabelComponent,
        AuiInputComponent,
        AuiFormFieldComponent,
    ],
    template: `
        <form class="flex flex-col space-y-4 md:space-y-6" [formGroup]="form" (ngSubmit)="handleFormSubmit()">
            <aui-form-layout>
                <aui-form-field>
                    <aui-form-field-label [label]="'AppthorAccount::EmailAddress' | abpLocalization" />
                    <aui-input
                        type="text"
                        [formControl]="form.controls.emailAddress"
                        [placeholder]="emailAddressPlaceholder"
                    />
                </aui-form-field>
                <aui-button
                    type="submit"
                    [label]="'AppthorAccount::ResetMyPassword' | abpLocalization"
                    [disabled]="form.invalid"
                    [extend]="true"
                    [size]="'40'"
                    [color]="'primary'"
                />
            </aui-form-layout>
        </form>
        <span class="aui-font-regular-14 block pb-3">
            {{ 'AppthorAccount::SendPasswordResetLink_Information' | abpLocalization }}
        </span>
    `,
})
export class CoreAccountForgotPasswordFormComponent {
    public readonly form = new FormGroup({
        emailAddress: new FormControl('', {
            nonNullable: true,
            validators: [Validators.required],
        }),
    });

    emailAddressPlaceholder = inject(EMAIL_ADDRESS_PLACEHOLDER);

    private readonly facade = inject(ResetPasswordFacade);

    handleFormSubmit() {
        this.facade.sendPasswordResetCode({
            email: this.form.controls.emailAddress.value,
        });
    }
}
