import { Component, inject } from '@angular/core';
import { AsyncPipe, JsonPipe, NgForOf, NgIf } from '@angular/common';

import { LocalizationModule } from '@abp/ng.core';
import { ButtonModule } from 'primeng/button';
import { CoreAccountExternalLoginStore } from './core-account-external-login.store';

@Component({
    standalone: true,
    imports: [NgIf, AsyncPipe, NgForOf, LocalizationModule, JsonPipe, ButtonModule],
    providers: [CoreAccountExternalLoginStore],
    selector: 'core-account-external-login',
    template: `
        <ng-container *ngIf="externalLoginState.vm$ | async as vm">
            <ng-container *ngIf="vm.isExternalProviderAvailable">
                <div *ngFor="let provider of vm.providers" class="my-2">
                    <p-button (click)="externalLoginState.loginWithProvider(provider)" [label]="provider.displayName" />
                </div>
            </ng-container>
        </ng-container>
    `,
})
export class ExternalLoginComponent {
    public readonly externalLoginState = inject(CoreAccountExternalLoginStore);
}
