import { ComponentStore } from '@ngrx/component-store';
import { inject, Injectable } from '@angular/core';
import { AuthenticatorLoginFacade, LoginResultType, LoginWithAuthenticator } from '@ralba-ng/core/account/domain';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs';

@Injectable()
export class AuthenticatorLoginStore extends ComponentStore<never> {
    form = new FormGroup({
        twoFactorCode: new FormControl(null, {
            nonNullable: true,
            validators: [
                Validators.required,
                Validators.minLength(6),
                Validators.maxLength(7),
                Validators.pattern(/^[0-9 ]*$/),
            ],
        }),
        rememberMachine: new FormControl(false, { nonNullable: true }),
    });

    authenticatorLoginFacade = inject(AuthenticatorLoginFacade);
    private readonly invalidAuthenticatorCodeResult$ = this.authenticatorLoginFacade.loginResult$.pipe(
        map(({ result }) => result === LoginResultType.InvalidUserNameOrPassword),
        startWith(false)
    );
    private readonly userLockedResult$ = this.authenticatorLoginFacade.loginResult$.pipe(
        map(({ result }) => result === LoginResultType.LockedOut),
        startWith(false)
    );
    public readonly vm$ = this.select({
        invalidAuthenticatorCodeResult: this.invalidAuthenticatorCodeResult$,
        userLockedResult: this.userLockedResult$,
    });

    public handleFormSubmit() {
        if (this.form.valid) {
            this.authenticatorLoginFacade.login(LoginWithAuthenticator.parse(this.form.getRawValue()));
        }
    }
}
