import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { EnvironmentProviders, Inject, Injectable, InjectionToken, Optional, Provider, Type } from '@angular/core';

export abstract class AbstractLocalEffectsContributor {
    public abstract effects: (() => Observable<unknown>)[];
}

export const LOCALE_EFFECTS_CONTRIBUTORS = new InjectionToken<AbstractLocalEffectsContributor[]>(
    'Local effects Contributors'
);

export const createLocalEffectsContributorInjectionToken = <T extends string>(featureName: T) =>
    new InjectionToken<AbstractLocalEffectsContributor[]>(featureName + ' Local effects Contributors');

@Injectable()
export class LocalEffectsStore extends ComponentStore<never> {
    constructor(
        @Optional()
        @Inject(LOCALE_EFFECTS_CONTRIBUTORS)
        readonly contributors: AbstractLocalEffectsContributor[] | null
    ) {
        super();
        if (contributors)
            Array.from(new Set(contributors))
                .filter((f) => f !== null)
                .forEach((contributor) => {
                    contributor.effects.forEach((effect) => {
                        this.effect(effect);
                    });
                });
    }
}

export const provideLocalEffects = <T extends AbstractLocalEffectsContributor>(
    injectionToken: InjectionToken<T>
): Provider[] => [{ provide: LOCALE_EFFECTS_CONTRIBUTORS, useExisting: injectionToken }, LocalEffectsStore];

export interface IHasEffectsContributors {
    effects?: Type<AbstractLocalEffectsContributor>[];
}

export const configureLocalEffects = <T extends AbstractLocalEffectsContributor>(
    config: IHasEffectsContributors,
    injectionToken: InjectionToken<T>
): Provider[] => [registerEffects(config, injectionToken)];

const registerEffects = <T extends AbstractLocalEffectsContributor>(
    config: IHasEffectsContributors,
    injectionToken: InjectionToken<T>
): (Provider | EnvironmentProviders)[] => {
    if (config.effects) {
        return config.effects.map((contributor) => ({
            provide: injectionToken,
            useClass: contributor,
            multi: true,
        }));
    }
    return [{ provide: injectionToken, useValue: null, multi: true }];
};
