import { Directive, inject } from '@angular/core';
import {
    ControlValueAccessor,
    FormControlDirective,
    FormControlName,
    NG_VALUE_ACCESSOR,
    NgControl,
    NgModel,
} from '@angular/forms';

export function injectNgControl() {
    const ngControl = inject(NgControl, { self: true, optional: true });

    if (!ngControl) throw new Error('...');

    if (
        ngControl instanceof FormControlDirective ||
        ngControl instanceof FormControlName ||
        ngControl instanceof NgModel
    ) {
        return ngControl;
    }

    throw new Error('...');
}

@Directive({
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: CommonValueAccessorDirective,
        },
    ],
    standalone: true,
})
export class CommonValueAccessorDirective implements ControlValueAccessor {
    writeValue(): void {
        //
    }

    registerOnChange(): void {
        //
    }

    registerOnTouched(): void {
        //
    }
}
