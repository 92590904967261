import { LocalizationModule } from '@abp/ng.core';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { RecoveryCodeLoginComponent } from '@ralba-ng/core/account/feature-recovery-code-login';
import { CoreAccountUiBackToLoginComponent } from '@ralba-ng/core/account/ui-back-to-login';

@Component({
    standalone: true,
    selector: 'auth-shell-login-authenticator-page',
    imports: [LocalizationModule, RouterLink, RecoveryCodeLoginComponent, CoreAccountUiBackToLoginComponent],
    template: `
        <h1 class="aui-font-bold-20 pb-2">
            {{ 'AppthorAccount::LoginWithRecoveryCode' | abpLocalization }}
        </h1>

        <div>
            <core-account-recovery-code-login />
        </div>

        <core-account-ui-back-to-login />
    `,
})
export class LoginRecoveryCodePageComponent {}
