import { inject, Injectable } from '@angular/core';
import { exhaustMap, map, Observable, withLatestFrom } from 'rxjs';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { ConfigStateService } from '@abp/ng.core';
import { AuthStore } from '../services/auth.state';
import { LoginHttpService } from '../infrastructure/login-http.service';
import { LoginWithAuthenticator } from '../entities/login-with-authenticator';

@Injectable({ providedIn: 'root' })
export class AuthenticatorLoginFacade extends ComponentStore<never> {
    private readonly config = inject(ConfigStateService);
    private readonly authState = inject(AuthStore);
    public readonly loginResult$ = this.authState.loginResult$;
    private readonly loginHttpService = inject(LoginHttpService);
    private readonly handleLoginWithAuthenticator$ = this.effect(
        ($: Observable<Omit<LoginWithAuthenticator, 'rememberMe'>>) =>
            $.pipe(
                withLatestFrom(this.authState.rememberMe$),
                map(([input, rememberMe]) => ({ input, rememberMe })),
                exhaustMap(({ input, rememberMe }) =>
                    this.loginHttpService
                        .loginWithAuthenticator({
                            ...input,
                            rememberMe,
                        })
                        .pipe(
                            tapResponse({
                                next: (loginResult) => {
                                    this.authState.updateLoginResult(loginResult);
                                },
                                error: (error) => {
                                    console.error(error);
                                },
                            })
                        )
                )
            )
    );

    public login(input: Omit<LoginWithAuthenticator, 'rememberMe'>) {
        this.handleLoginWithAuthenticator$(input);
    }
}
