import { z } from 'zod';
import { ExternalProvider } from './external-provider';

export const AuthConfiguration = z.object({
    enableLocalLogin: z.boolean(),
    isSelfRegistrationEnabled: z.boolean(),
    externalProviders: z.array(ExternalProvider),
});

export type AuthConfiguration = z.infer<typeof AuthConfiguration>;
