import { computed, effect } from '@angular/core';
import { patchState, signalState } from '@ngrx/signals';
import { AuiThemeColorsSet } from '@ral/utils:core/theme';
import { createInjectionToken } from 'ngxtension/create-injection-token';
import { AppLevelTheme, injectAppLevelThemes } from './aui-theme-token';

export type AuiThemes = Record<string, AuiThemeColorsSet>;

const SELECTED_THEME = 'SELECTED_THEME';

/**
 * For root theme management
 
 */
export const [injectAuiThemeStore, , AuiThemeStoreToken] = createInjectionToken(() => {
    const appLevelThemes = injectAppLevelThemes({ optional: true });

    const state = signalState({
        selectedThemeName: null as string | null,
        colors: {} as AuiThemeColorsSet,
        themes:
            appLevelThemes?.reduce(
                (prev, curr) => {
                    return {
                        ...prev,
                        [curr.themeKey]: {
                            defaultPriorityIndex: curr.defaultPriorityIndex,
                            themeName: curr.themeName,
                            theme: curr.theme,
                        },
                    };
                },
                {} as Record<string, Omit<AppLevelTheme, 'themeKey'>>
            ) ?? {},
    });

    // Find and set theme
    const selectTheme = (themeName: string, saveAsUserPreferred = true) => {
        const themes = state.themes();
        if (themeName in themes) {
            if (saveAsUserPreferred) {
                localStorage.setItem(SELECTED_THEME, themeName);
            }
            patchState(state, {
                selectedThemeName: themeName,
                colors: {
                    ...themes[themeName].theme,
                },
            });
        }
    };

    // Update <HTML> styles
    effect(() => {
        // setup inline styles
        const s = document.querySelector(':root') as HTMLElement;
        s.removeAttribute('style');
        Object.entries(state.colors()).forEach(([key, value]) => {
            s?.style.setProperty(key, value + '');
        });
    });

    const availableThemes = computed(() => {
        return Object.entries(state.themes()).map(([key]) => key);
    });

    const availableThemesWithoutCurrent = computed(() => {
        const current = state.selectedThemeName();
        return availableThemes().filter((p) => current !== p);
    });

    // Initialization
    const loadIniValues = () => {
        const themes = state.themes();
        const savedSelectedTheme = localStorage.getItem(SELECTED_THEME);

        if (savedSelectedTheme) {
            if (themes[savedSelectedTheme] && themes[savedSelectedTheme].defaultPriorityIndex) {
                selectTheme(savedSelectedTheme, false);
                return;
            }
        }

        const themeWithHighestPriority = Object.entries(themes).reduce(
            (prev, [key, value]) =>
                prev && themes[prev] && themes[prev].defaultPriorityIndex > value.defaultPriorityIndex ? prev : key,
            null as string | null
        );
        if (themeWithHighestPriority) selectTheme(themeWithHighestPriority, false);
    };

    const registerTheme = (theme: AppLevelTheme) => {
        const themes = state.themes();

        patchState(state, {
            themes: {
                ...themes,
                ...{
                    [theme.themeKey]: theme,
                },
            },
        });

        loadIniValues();
    };

    loadIniValues();
    return {
        registerTheme,
        selectTheme,
        selectedTheme: state.selectedThemeName,
        currentThemeColors: state.colors,
        availableThemes,
        availableThemesWithoutCurrent,
    };
});
