import { inject } from '@angular/core';
import { IToastData, provideToastService } from '@ral/utils:core/toast';
import { createInjectionToken } from 'ngxtension/create-injection-token';
import { ToastsStore } from '../store/aui-toast.store';

export type Position = 'top-left' | 'bottom-left' | 'top-right' | 'bottom-right';

function defaultToastOptions() {
    return { position: 'bottom-right' as Position, duration: 3000 };
}

export const [injectAuiToastDefaultOptions, provideAuiToastDefaultOptions] = createInjectionToken(defaultToastOptions);

export const provideAuiToastService = provideToastService(() => {
    const toastService = inject(ToastsStore);

    function info(data: IToastData) {
        toastService.addToast(data, 'info');
    }

    function error(data: IToastData) {
        toastService.addToast(data, 'error');
    }

    function warning(data: IToastData) {
        toastService.addToast(data, 'warning');
    }

    function success(data: IToastData) {
        toastService.addToast(data, 'success');
    }

    return {
        info,
        error,
        warning,
        success,
    };
});
