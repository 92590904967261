import { inject, Injectable } from '@angular/core';
import { exhaustMap, Observable } from 'rxjs';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { ConfigStateService } from '@abp/ng.core';
import { AuthStore } from '../services/auth.state';
import { LoginHttpService } from '../infrastructure/login-http.service';
import { LoginWithRecoveryCode } from '../entities/login-with-recovery-codes';

@Injectable({ providedIn: 'root' })
export class RecoveryCodeLoginFacade extends ComponentStore<never> {
    private readonly config = inject(ConfigStateService);
    private readonly authState = inject(AuthStore);
    public readonly loginResult$ = this.authState.loginResult$;
    private readonly loginHttpService = inject(LoginHttpService);
    private readonly handleLoginWithRecoveryCode$ = this.effect(($: Observable<LoginWithRecoveryCode>) =>
        $.pipe(
            exhaustMap((input) =>
                this.loginHttpService.loginWithRecoveryCodes(input).pipe(
                    tapResponse({
                        next: (loginResult) => {
                            this.authState.updateLoginResult(loginResult);
                        },
                        error: (error) => {
                            console.error(error);
                        },
                    })
                )
            )
        )
    );

    public login(input: LoginWithRecoveryCode) {
        this.handleLoginWithRecoveryCode$(input);
    }
}
