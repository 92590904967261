import { createInjectionToken } from 'ngxtension/create-injection-token';
import { IConfirmation } from './confirmation.interface';

export const [injectConfirmation, provideConfirmation] = createInjectionToken(() => {
    function confirm(args: IConfirmation) {
        if (window.confirm(args.message)) {
            if (args.accept) {
                args.accept();
                return;
            }
        }

        if (args.reject) {
            args.reject();
        }
    }

    return {
        confirm,
    };
});
