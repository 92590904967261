export class NSwagException<TResult = object> extends Error {
    override message: string;
    status: number;
    response: string;
    headers: { [key: string]: unknown };
    result: TResult;
    protected isApiException = true;

    constructor(
        message: string,
        status: number,
        response: string,
        headers: {
            [key: string]: unknown;
        },
        result: TResult
    ) {
        super();

        this.message = message;
        this.status = status;
        this.response = response;
        this.headers = headers;
        this.result = result;
    }

    static isApiException(obj: object): obj is NSwagException {
        return 'isApiException' in obj && obj.isApiException === true;
    }
}
