import { patchState, signalStore, withComputed, withMethods } from '@ngrx/signals';
import { IToastData } from '@ral/utils:core/toast';
import { Toast, ToastSeverity } from '../model';
import { EntityId, removeEntity, setAllEntities, withEntities } from '@ngrx/signals/entities';
import { computed } from '@angular/core';

export const ToastsStore = signalStore(
    { providedIn: 'root' },
    withEntities<Toast>(),
    withComputed((store) => {
        const nextId = computed(() => {
            const lastId = store.ids().sort((a, b) => (a < b ? 1 : -1))[0] ?? 0;
            return +lastId + 1;
        });
        const toasts = computed(() => store.entities());

        return {
            toasts,
            nextId,
        };
    }),
    withMethods(({ nextId, toasts, ...store }) => {
        const removeToast = (id: EntityId) => {
            patchState(store, removeEntity(id));
        };

        const addToast = (data: IToastData, severity: ToastSeverity) => {
            const toast: Toast = { ...data, id: nextId(), type: severity };
            const prev = toasts().reverse().slice(0, 2).reverse();

            patchState(store, setAllEntities([...prev, toast]));
        };

        return {
            removeToast,
            addToast,
        };
    })
);
