export interface RemoteServiceErrorInfo {
    code: string | null;
    message: string | null;
    details: string | null;
    data: { [key: string]: unknown } | null;
    validationErrors: RemoteServiceValidationErrorInfo[] | null;
}

export interface RemoteServiceValidationErrorInfo {
    message: string | null;
    members: string[] | null;
}

export function isRemoteServiceErrorInfo(obj: object): obj is { error: RemoteServiceErrorInfo } {
    return true;
}
