import { LocalizationModule } from '@abp/ng.core';
import { Component } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { LocalLoginComponent } from '@ralba-ng/core/account/feature-local-login';
import { CoreAuthResetPasswordComponent } from '@ralba-ng/core/account/feature-reset-password';
import { CoreAccountUiBackToLoginComponent } from '@ralba-ng/core/account/ui-back-to-login';

@Component({
    standalone: true,
    selector: 'auth-shell-login-page',
    imports: [
        LocalizationModule,
        LocalLoginComponent,
        RouterLink,
        CoreAuthResetPasswordComponent,
        RouterOutlet,
        CoreAccountUiBackToLoginComponent,
    ],
    template: `
        <h1 class="aui-font-bold-20 pb-2">
            {{ 'AppthorAccount::ResetPassword' | abpLocalization }}
        </h1>

        <div>
            <router-outlet></router-outlet>
        </div>
        <div>
            <core-account-ui-back-to-login />
        </div>
    `,
})
export class ResetPasswordPageComponent {}
