import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '@abp/ng.core';
import { Observable, switchMap } from 'rxjs';
import { AuthConfiguration } from '../entities/auth-configuration';
import { LoginResult } from '../entities/login-result';
import { LoginWithAuthenticator } from '../entities/login-with-authenticator';
import { LoginWithPassword } from '../entities/login-with-password';
import { LoginWithRecoveryCode } from '../entities/login-with-recovery-codes';

@Injectable({ providedIn: 'root' })
export class LoginHttpService {
    protected readonly http = inject(HttpClient);
    protected readonly environment = inject(EnvironmentService);

    public getConfiguration(): Observable<AuthConfiguration> {
        return this.http
            .get('/auth/configuration')
            .pipe(switchMap((response) => AuthConfiguration.parseAsync(response)));
    }

    public login(input: LoginWithPassword): Observable<LoginResult> {
        return this.http.post('/auth/login', input).pipe(switchMap((response) => LoginResult.parseAsync(response)));
    }

    public loginWithAuthenticator(input: LoginWithAuthenticator): Observable<LoginResult> {
        return this.http
            .post('/auth/login/authenticator', input)
            .pipe(switchMap((response) => LoginResult.parseAsync(response)));
    }

    public loginWithRecoveryCodes(input: LoginWithRecoveryCode): Observable<LoginResult> {
        return this.http
            .post('/auth/login/recovery-code', input)
            .pipe(switchMap((response) => LoginResult.parseAsync(response)));
    }
}
