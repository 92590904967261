import { inject, Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ComponentStore } from '@ngrx/component-store';
import { RegisterFacade, RegisterModel } from '@ralba-ng/core/account/domain';

@Injectable()
export class RegisterStore extends ComponentStore<never> {
    form = new FormGroup({
        userName: new FormControl('', {
            nonNullable: true,
            validators: [Validators.required],
        }),
        emailAddress: new FormControl('', {
            nonNullable: true,
            validators: [Validators.required, Validators.email],
        }),
        password: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    });

    registerFacade = inject(RegisterFacade);

    public readonly vm$ = this.select({
        IsSelfRegistrationEnabled: this.registerFacade.IsSelfRegistrationEnabled$,
        error: this.registerFacade.error$,
    });

    public handleFormSubmit() {
        if (this.form.valid) {
            this.registerFacade.register(RegisterModel.parse(this.form.getRawValue()));
        }
    }
}
