import { LocalizationModule } from '@abp/ng.core';
import { Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AuiButtonComponent } from '@ral/ui:aui/button';
import { AuiCheckboxComponent } from '@ral/ui:aui/checkbox';
import { AuiFormFieldComponent, AuiFormFieldLabelComponent, AuiFormLayoutComponent } from '@ral/ui:aui/form-layout';
import { AuiInputComponent } from '@ral/ui:aui/input';
import { PASSWORD_PLACEHOLDER, USERNAME_OR_EMAIL_ADDRESS_PLACEHOLDER } from '@ralba-ng/core/account/domain';
import { LocalLoginStore } from '../local-login.store';

@Component({
    standalone: true,
    imports: [
        ReactiveFormsModule,
        LocalizationModule,
        AuiInputComponent,
        AuiCheckboxComponent,
        AuiFormLayoutComponent,
        AuiButtonComponent,
        AuiFormFieldComponent,
        AuiFormFieldLabelComponent,
        AuiButtonComponent,
    ],
    selector: 'core-account-local-login-form',
    template: `
        <form [formGroup]="form" (ngSubmit)="handleFormSubmit()">
            <aui-form-layout>
                <aui-form-field>
                    <aui-form-field-label [label]="'AppthorAccount::UserNameOrEmailAddress' | abpLocalization" />
                    <aui-input
                        type="text"
                        [formControl]="form.controls.userNameOrEmailAddress"
                        [placeholder]="usernameOrEmailAddressPlaceholder"
                    />
                </aui-form-field>

                <aui-form-field>
                    <aui-form-field-label [label]="'AppthorAccount::Password' | abpLocalization" />
                    <aui-input
                        type="password"
                        [formControl]="form.controls.password"
                        [placeholder]="passwordPlaceholder"
                    />
                </aui-form-field>

                <aui-form-field>
                    <aui-checkbox
                        [formControl]="form.controls.rememberMe"
                        [checkboxLabel]="'AppthorAccount::RememberMe' | abpLocalization"
                    />
                </aui-form-field>
                <aui-button
                    type="submit"
                    [label]="'AppthorAccount::Login' | abpLocalization"
                    [disabled]="form.invalid"
                    [extend]="true"
                    [size]="'40'"
                    [color]="'primary'"
                />
            </aui-form-layout>
        </form>
    `,
})
export class LocalLoginFormComponent {
    public readonly usernameOrEmailAddressPlaceholder = inject(USERNAME_OR_EMAIL_ADDRESS_PLACEHOLDER);
    public readonly passwordPlaceholder = inject(PASSWORD_PLACEHOLDER);
    private readonly state = inject(LocalLoginStore);
    public readonly form = this.state.form;

    public handleFormSubmit() {
        this.state.handleFormSubmit();
    }
}
