import { LocalizationModule } from '@abp/ng.core';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AuiButtonComponent } from '@ral/ui:aui/button';
import { AuiFormFieldComponent, AuiFormFieldLabelComponent, AuiFormLayoutComponent } from '@ral/ui:aui/form-layout';
import { AuiInputComponent } from '@ral/ui:aui/input';
import { RECOVERY_CODE_PLACEHGOLDER } from '@ralba-ng/core/account/domain';
import { RecoveryCodeLoginStore } from '../recovery-code-login.store';

import { FieldComponent } from '@ralba-ng/shared/forms/ui-field-wrapper';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        LocalizationModule,
        ReactiveFormsModule,
        FieldComponent,
        AuiInputComponent,
        AuiFormFieldComponent,
        AuiFormFieldLabelComponent,
        AuiButtonComponent,
        AuiFormLayoutComponent,
    ],
    providers: [RecoveryCodeLoginStore],
    selector: 'core-account-recovery-code-login-form',
    template: ` <form [formGroup]="form" (ngSubmit)="handleFormSubmit()">
        <aui-form-layout class="flex flex-col gap-4 pb-4">
            <aui-form-field>
                <aui-form-field-label [label]="'AppthorAccount::TwoFactorCode' | abpLocalization" />
                <aui-input
                    type="text"
                    [formControl]="form.controls.recoveryCode"
                    [placeholder]="recoveryCodePlaceholder"
                />
            </aui-form-field>
            <aui-button
                [color]="'primary'"
                type="submit"
                [label]="'AppthorAccount::Login' | abpLocalization"
                [disabled]="form.invalid"
                [extend]="true"
                [size]="'40'"
            />
        </aui-form-layout>
    </form>`,
})
export class RecoveryCodeLoginFormComponent {
    public readonly recoveryCodePlaceholder = inject(RECOVERY_CODE_PLACEHGOLDER);
    private readonly state = inject(RecoveryCodeLoginStore);
    public readonly form = this.state.form;

    public handleFormSubmit() {
        this.state.handleFormSubmit();
    }
}
