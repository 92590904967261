import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwitchTenantFacade } from '@ralba-ng/core/account/domain';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { LocalizationModule } from '@abp/ng.core';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { ComponentStore } from '@ngrx/component-store';
import { distinctUntilChanged, tap } from 'rxjs';

@Component({
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, LocalizationModule, DialogModule, ButtonModule],
    selector: 'core-account-switch-tenant',
    template: `
        <ng-container *ngIf="(currentTenant$ | async) || { name: '' } as currentTenant">
            <div class="card shadow-sm rounded mb-3">
                <div class="card-body px-5">
                    <div class="row">
                        <div class="col">
                            <span style="font-size: 0.8em" class="text-uppercase text-muted">{{
                                'AbpUiMultiTenancy::Tenant' | abpLocalization
                            }}</span
                            ><br />
                            <h6 class="m-0 d-inline-block">
                                <i>{{ currentTenant.name || ('AbpUiMultiTenancy::NotSelected' | abpLocalization) }}</i>
                            </h6>
                        </div>
                        <div class="col-auto">
                            <a class="btn btn-sm mt-3 btn-outline-primary" (click)="isDialogVisible = true">{{
                                'AbpUiMultiTenancy::Switch' | abpLocalization
                            }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <p-dialog
            [(visible)]="isDialogVisible"
            [header]="'AbpUiMultiTenancy::Tenant' | abpLocalization"
            [draggable]="false"
        >
            <label>{{ 'AbpUiMultiTenancy::Name' | abpLocalization }}</label>
            <input
                [formControl]="tenantControl"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
            />

            <ng-template pTemplate="footer">
                <p-button icon="pi pi-check" (click)="handleSubmit()" label="Ok" styleClass="p-button-text"></p-button>
            </ng-template>
        </p-dialog>
    `,
})
export class SwitchTenantComponent extends ComponentStore<never> {
    public isDialogVisible = false;
    tenantControl = new FormControl(null);

    private switchTenantFacade = inject(SwitchTenantFacade);
    currentTenant$ = this.switchTenantFacade.currentTenant$;

    private readonly onTenantChanged$ = this.effect(() =>
        this.currentTenant$.pipe(
            distinctUntilChanged(),
            tap(() => (this.isDialogVisible = false))
        )
    );

    handleSubmit() {
        this.switchTenantFacade.switchTenant(this.tenantControl.value || null);
    }
}
