import { LocalizationModule } from '@abp/ng.core';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AuthenticatorLoginComponent } from '@ralba-ng/core/account/feature-authenticator-login';
import { CoreAccountUiBackToLoginComponent } from '@ralba-ng/core/account/ui-back-to-login';

@Component({
    standalone: true,
    selector: 'auth-shell-login-authenticator-page',
    imports: [LocalizationModule, RouterLink, AuthenticatorLoginComponent, CoreAccountUiBackToLoginComponent],
    template: `
        <h1 class="aui-font-bold-20 pb-2">
            {{ 'AppthorAccount::Login' | abpLocalization }}
        </h1>

        <div>
            <core-account-authenticator-login />
        </div>

        <p class="text-sm text-gray-500 dark:text-gray-400">
            <a
                [routerLink]="['..', 'recovery-code']"
                queryParamsHandling="merge"
                class="text-sm font-medium text-gray-500 dark:text-gray-400"
                >{{ 'AppthorAccount::LoginWithRecoveryCode' | abpLocalization }}</a
            >
        </p>

        <core-account-ui-back-to-login />
    `,
})
export class LoginAuthenticatorPageComponent {}
