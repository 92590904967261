import { z } from 'zod';
import { LoginResult } from '../entities/login-result';
import { inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { filter, map, tap, withLatestFrom } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ConfigStateService } from '@abp/ng.core';
import { ConfigConst } from '../const/config';
import { LoginResultType } from '../entities/login-result-types';

export const AuthState = z.object({
    loginResult: z.nullable(LoginResult),
    rememberMe: z.boolean(),
});
export type AuthState = z.infer<typeof AuthState>;

@Injectable({ providedIn: 'root' })
export class AuthStore extends ComponentStore<AuthState> {
    public readonly loginResult$ = this.select((s) => s.loginResult).pipe(
        filter((LoginResult): LoginResult is LoginResult => LoginResult !== null)
    );

    public readonly rememberMe$ = this.select((s) => s.rememberMe);
    public readonly updateLoginResult = this.updater((state, loginResult: LoginResult) => ({
        ...state,
        loginResult,
    }));
    protected readonly activatedRoute = inject(ActivatedRoute);
    protected readonly config = inject(ConfigStateService);
    public readonly returnUrl$ = this.activatedRoute.queryParams.pipe(
        map((params) => params['ReturnUrl'] || this.config.getSetting(ConfigConst.defaultRedirectUrl))
    );

    private readonly onSuccess$ = this.effect<void>(() =>
        this.loginResult$.pipe(
            map(({ result }) => result),
            filter((f) => f === LoginResultType.Success),
            withLatestFrom(this.returnUrl$),
            map(([result, returnUrl]) => ({ result, returnUrl })),
            tap(({ returnUrl }) => {
                location.replace(returnUrl);
            })
        )
    );

    constructor() {
        super({ loginResult: null, rememberMe: false });
        this.onSuccess$();
    }
}
