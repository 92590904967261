import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '@abp/ng.core';
import { ProxyGenerated } from '../../proxy/generated';
import { ResetPasswordInput, SendPasswordResetCode, VerifyPasswordResetTokenInput } from './dto';
import { catchError, Observable } from 'rxjs';
import { throwErrorResponse } from '@ralba-ng/shared/util-core';

@Injectable({ providedIn: 'root' })
export class AccountDataSource {
    private readonly _httpClient = inject(HttpClient);
    private readonly _environment = inject(EnvironmentService);

    private get proxy(): ProxyGenerated.AccountProxy {
        return new ProxyGenerated.AccountProxy(this._httpClient, this._environment.getApiUrl('account'));
    }

    public sendPasswordResetCode(input: SendPasswordResetCode): Observable<void> {
        return this.proxy
            .sendPasswordResetCode({
                appName: input.appName,
                email: input.email,
                returnUrl: input.returnUrl || '',
                returnUrlHash: input.returnUrlHash || '',
            })
            .pipe(
                catchError(({ error }: ProxyGenerated.RemoteServiceErrorResponse) => {
                    throwErrorResponse(error);
                })
            );
    }

    public resetPassword(input: ResetPasswordInput): Observable<void> {
        return this.proxy.resetPassword(input).pipe(
            catchError(({ error }: ProxyGenerated.RemoteServiceErrorResponse) => {
                throwErrorResponse(error);
            })
        );
    }

    public validatePasswordResetToken(input: VerifyPasswordResetTokenInput): Observable<boolean> {
        return this.proxy.verifyPasswordResetToken(input).pipe(
            catchError(({ error }: ProxyGenerated.RemoteServiceErrorResponse) => {
                throwErrorResponse(error);
            })
        );
    }
}
