import { ConfigStateService, LocalizationModule, MultiTenancyService } from '@abp/ng.core';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { SwitchTenantComponent } from './components/switch-tenant.component';

@Component({
    standalone: true,
    imports: [CommonModule, LocalizationModule, SwitchTenantComponent],
    selector: 'core-account-tenant-box',
    template: `
        <ng-container *ngIf="(isMultiTenancyEnabled$ | async) && isTenantBoxVisible">
            <core-account-switch-tenant />
        </ng-container>
    `,
})
export class TenantBoxComponent extends ComponentStore<never> {
    public readonly multiTenancy = inject(MultiTenancyService);
    private configState = inject(ConfigStateService);
    isMultiTenancyEnabled$ = this.configState.getDeep$('multiTenancy.isEnabled');
    private route = inject(ActivatedRoute);

    get isTenantBoxVisibleForCurrentRoute() {
        const data = this.getMostInnerChild().data;
        if ('tenantBoxVisible' in data) {
            return data['tenantBoxVisible'] ?? true;
        }
        return true;
    }

    get isTenantBoxVisible() {
        return this.isTenantBoxVisibleForCurrentRoute && this.multiTenancy.isTenantBoxVisible;
    }

    private getMostInnerChild() {
        let child = this.route.snapshot;
        let depth = 0;
        const depthLimit = 10;
        while (child.firstChild && depth < depthLimit) {
            child = child.firstChild;
            depth++;
        }
        return child;
    }
}
