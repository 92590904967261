import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { OverlayModule } from 'primeng/overlay';

@Component({
    selector: 'aui-tooltip',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [CommonModule, OverlayModule],
    styleUrl: 'aui-tooltip.component.scss',
    template: `
        <div
            class="tooltip rounded border border-surface-background-darken p-2 aui-font-regular-14 text-center bg-surface-background-lighter"
        >
            <span>{{ tooltip }}</span>
        </div>
    `,
})
export class TooltipComponent {
    tooltip: string | null | undefined;
}
