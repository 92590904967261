import { inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { ImpersonationService } from '../services/impersonation.service';
import { ConfigStateService } from '@abp/ng.core';
import { distinctUntilChanged, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ImpersonationFacade extends ComponentStore<never> {
    protected readonly impersonation = inject(ImpersonationService);
    protected readonly config = inject(ConfigStateService);

    public isImpersonationVisible$ = this.config.getAll$().pipe(
        map(({ currentUser: { impersonatorUserId } }) => !!impersonatorUserId),
        distinctUntilChanged()
    );

    public impersonateUser(userId: string) {
        this.impersonation.impersonateUser(userId);
    }

    public impersonateTenant(tenantId: string) {
        this.impersonation.impersonateTenant(tenantId);
    }

    public backToImpersonator() {
        this.impersonation.impersonate({});
    }

    public isImpersonationVisible() {
        return this.impersonation.isImpersonatorVisible();
    }
}
