import { Route, RouterOutlet } from '@angular/router';
// import { authRoutes } from './features/auth/auth.routes';
import { LazyLoadService } from '@abp/ng.core';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AuthLayoutComponent } from './components/auth-layout.component';

import { authRoutes } from '@ralba-ng/core/shell/auth';

@Component({
    standalone: true,
    selector: 'ralba-ng-empty-layout',
    imports: [CommonModule, RouterOutlet],
    template: ` <router-outlet></router-outlet> `,
})
export class EmptyLayoutComponent {
    constructor(private lazyLoad: LazyLoadService) {}
}

export const appRoutes: Route[] = [
    {
        path: '',
        children: authRoutes(AuthLayoutComponent),
        providers: [],
        resolve: {},
    },
];
