import { ComponentStore } from '@ngrx/component-store';
import { inject, Injectable } from '@angular/core';
import { LocalLoginFacade, LoginResultType, LoginWithPassword } from '@ralba-ng/core/account/domain';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs';

@Injectable()
export class LocalLoginStore extends ComponentStore<never> {
    form = new FormGroup({
        userNameOrEmailAddress: new FormControl(null, {
            nonNullable: true,
            validators: [Validators.required],
        }),
        password: new FormControl(null, { nonNullable: true, validators: [Validators.required] }),
        rememberMe: new FormControl(false, { nonNullable: true }),
    });

    localLoginFacade = inject(LocalLoginFacade);
    private readonly invalidUserNameOrPasswordResult$ = this.localLoginFacade.loginResult$.pipe(
        map(({ result }) => result === LoginResultType.InvalidUserNameOrPassword),
        startWith(false)
    );
    private readonly userLockedResult$ = this.localLoginFacade.loginResult$.pipe(
        map(({ result }) => result === LoginResultType.LockedOut),
        startWith(false)
    );
    public readonly vm$ = this.select({
        isLocalLoginEnabled: this.localLoginFacade.isLocalLoginEnabled$,
        invalidUserNameOrPasswordResult: this.invalidUserNameOrPasswordResult$,
        userLockedResult: this.userLockedResult$,
    });

    public handleFormSubmit() {
        if (this.form.valid) {
            this.localLoginFacade.login(LoginWithPassword.parse(this.form.getRawValue()));
        }
    }
}
