import { createInjectionToken } from 'ngxtension/create-injection-token';
import { User } from './user';
import { signal, Signal } from '@angular/core';

export const [injectAuthService, provideAuthService] = createInjectionToken(() => {
    return {
        isAuthenticated: signal(true) as Signal<boolean>,
        currentUser: signal(null) as Signal<User | null>,
        logout: (): void => {
            console.warn('Function is not provided');
        },
    };
});
