import { INJECTOR, Injector, inject } from '@angular/core';
import { createInjectionToken, createNoopInjectionToken } from 'ngxtension/create-injection-token';

export type AuiLocalizationFn<T extends object | never | null = never> = (args?: T) => string;

export type AuiTranslations = {
    datepickerPlaceholder: AuiLocalizationFn;
    inputPlaceholder: AuiLocalizationFn;
    filterPlaceholder: AuiLocalizationFn;
    selectPlaceholder: AuiLocalizationFn;
    multiselectPlaceholder: AuiLocalizationFn;
    hours: AuiLocalizationFn;
    minutes: AuiLocalizationFn;
    seconds: AuiLocalizationFn;
    period: AuiLocalizationFn;
    noItems: AuiLocalizationFn;
    day: AuiLocalizationFn;
    month: AuiLocalizationFn;
    year: AuiLocalizationFn;
    error: AuiLocalizationFn;
    warning: AuiLocalizationFn;
    success: AuiLocalizationFn;
    info: AuiLocalizationFn;
    accept: AuiLocalizationFn;
    reject: AuiLocalizationFn;
    confirmationHeader: AuiLocalizationFn;
    confirmationMessage: AuiLocalizationFn;
    add: AuiLocalizationFn;
    sorting: AuiLocalizationFn;
    download: AuiLocalizationFn;
    preview: AuiLocalizationFn;
    fileDragAndDrop: AuiLocalizationFn;
    or: AuiLocalizationFn;
    fileBrowse: AuiLocalizationFn;
    selectSorting: AuiLocalizationFn;
    searchKeyword: AuiLocalizationFn;
    loading: AuiLocalizationFn;
    spotlightFilterPlaceholder: AuiLocalizationFn;
    [key: string]: AuiLocalizationFn<never>;
};

export const [injectAuiTranslations, provideAuiTranslations] = createNoopInjectionToken<
    () => Partial<AuiTranslations>,
    false
>('Aui Translation');

export const [injectAuiTranslationDictionary, provideAuiTranslationDictionary] = createInjectionToken<
    () => AuiTranslations
>(() => {
    const injectRecursively = (
        injector: Injector | undefined | null,
        _prev: Partial<AuiTranslations>
    ): Partial<AuiTranslations> => {
        if (injector === undefined || injector === null) return _prev;
        const current = injectAuiTranslations({ optional: true, injector });
        if (current === null || current === undefined) return _prev;
        return injectRecursively(injector.get(INJECTOR, undefined, { skipSelf: true, optional: true }), {
            ...current,
            ..._prev,
        });
    };

    return {
        datepickerPlaceholder: () => 'Select date',
        inputPlaceholder: () => 'Enter value',
        filterPlaceholder: () => 'Filter items',
        selectPlaceholder: () => 'Select item',
        multiselectPlaceholder: () => 'Select items',
        noItems: () => 'No items',
        period: () => 'Period',
        hours: () => 'Hours',
        minutes: () => 'Minutes',
        seconds: () => 'Seconds',
        day: () => 'Day',
        month: () => 'Month',
        year: () => 'Year',
        error: () => 'Error',
        warning: () => 'Warning',
        success: () => 'Success',
        info: () => 'Info',
        accept: () => 'Accept',
        reject: () => 'Reject',
        confirmationHeader: () => 'Are you sure ?',
        confirmationMessage: () => 'Are you sure that you want to perform this action ?',
        add: () => 'Add',
        sorting: () => 'Sorting',
        download: () => 'Download',
        preview: () => 'Preview',
        fileDragAndDrop: () => 'Drag and drop file here',
        or: () => 'or',
        fileBrowse: () => 'Browse for file of maximum size',
        selectSorting: () => 'Select sorting',
        searchKeyword: () => 'Search keyword',
        loading: () => 'Loading',
        spotlightFilterPlaceholder: () => 'Filter commands in spotlight',
        ...injectRecursively(inject(INJECTOR), {} as AuiTranslations),
    };
});
