import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalizationModule } from '@abp/ng.core';
import { ReactiveFormsModule } from '@angular/forms';
import { RegisterStore } from './register.store';
import { RegisterFromComponent } from './components/register-form.component';

@Component({
    standalone: true,
    imports: [CommonModule, LocalizationModule, ReactiveFormsModule, RegisterFromComponent],
    providers: [RegisterStore],
    selector: 'core-account-register',
    template: `
        <ng-container *ngIf="state.vm$ | async as vm">
            <ng-container *ngIf="vm.IsSelfRegistrationEnabled; else selfRegistrationDisabled">
                <small *ngIf="vm.error" class="p-error">{{ vm.error.message }}</small>
                <core-account-register-form />
            </ng-container>

            <ng-template #selfRegistrationDisabled>
                <p class="text-error">
                    {{ 'AppthorAccount::SelfRegistrationDisabledMessage' | abpLocalization }}
                </p>
            </ng-template>
        </ng-container>
    `,
})
export class RegisterComponent {
    public readonly state = inject(RegisterStore);
}
