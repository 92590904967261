import { Component, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { AuthStore, LoginResultType } from '@ralba-ng/core/account/domain';
import { Router, RouterOutlet } from '@angular/router';
import { filter, tap } from 'rxjs';

@Component({
    standalone: true,
    imports: [RouterOutlet],
    selector: 'auth-shell-auth',
    template: ` <router-outlet></router-outlet> `,
})
export class AuthComponent extends ComponentStore<never> {
    authState = inject(AuthStore);
    router = inject(Router);

    twoFactorRequired$ = this.effect<void>(() =>
        this.authState.loginResult$.pipe(
            filter(({ result }) => result === LoginResultType.RequiresTwoFactor),
            tap(() => {
                this.router.navigate(['login', 'authenticator'], {
                    queryParamsHandling: 'preserve',
                });
            })
        )
    );
}
