import { AuthService, ConfigStateService, EnvironmentService, WebHttpUrlEncodingCodec } from '@abp/ng.core';
import { OAuthService } from 'angular-oauth2-oidc';
import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { finalize, from, switchMap, tap } from 'rxjs';

const grantType = 'Impersonation';

@Injectable({ providedIn: 'root' })
export class ImpersonationService {
    oAuthService = inject(OAuthService);
    authService = inject(AuthService);
    configState = inject(ConfigStateService);
    environment = inject(EnvironmentService);
    http = inject(HttpClient);

    get params() {
        const { clientId, dummyClientSecret } = this.oAuthService;
        const p = new HttpParams({ encoder: new WebHttpUrlEncodingCodec() })
            .set('access_token', this.oAuthService.getAccessToken())
            .set('grant_type', grantType)
            .set('client_id', clientId || '');
        if (dummyClientSecret) {
            return p.set('client_secret', dummyClientSecret);
        }
        return p;
    }

    get tokenEndpoint() {
        return this.oAuthService.tokenEndpoint || '';
    }

    isImpersonatorVisible() {
        const {
            currentUser: { impersonatorUserId: userId },
        } = this.configState.getAll();
        return !!userId;
    }

    impersonateUser(userId: string) {
        const { currentTenant } = this.configState.getAll();
        return this.impersonate({ userId, tenantId: currentTenant.id });
    }

    impersonateTenant(tenantId: string) {
        return this.impersonate({ tenantId });
    }

    impersonate({ tenantId, userId, tenantUserName }: { tenantId?: string; userId?: string; tenantUserName?: string }) {
        const params: Record<string, string> = {};
        if (tenantId) {
            params['TenantId'] = tenantId;
        }
        if (userId) {
            params['UserId'] = userId;
        }
        if (tenantUserName) {
            params['TenantUserName'] = tenantUserName;
        }
        return this.environment
            .getEnvironment$()
            .pipe(
                tap(({ oAuthConfig }) => {
                    if (oAuthConfig && oAuthConfig.responseType === 'code') {
                        this.oAuthService.oidc = false;
                    }
                }),
                switchMap(() => {
                    const promiseOfLogin = this.authService.loginUsingGrant(grantType, params);
                    return from(promiseOfLogin).pipe(
                        finalize(() => {
                            location.href = this.environment.getEnvironment().application?.baseUrl || '/';
                        })
                    );
                })
            )
            .subscribe();
    }
}
