import { EnvironmentProviders, importProvidersFrom, Provider } from '@angular/core';

import { DialogModule } from '@angular/cdk/dialog';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAuiToastService } from '@ral/ui:aui/toast';

import { provideAuiConfirmation } from '@ral/ui:aui/confirmation';

import { DialogService } from 'primeng/dynamicdialog';

export const provideAuiBasicTheme = (): (Provider | EnvironmentProviders)[] => [
    provideAuiToastService,
    // routesProvider(),
    DialogService,
    provideAuiConfirmation,
    importProvidersFrom([DialogModule]),
    provideAnimations(),
];
