import { hexToHSL } from './colors.util';
import { AuiColorsType, AuiThemeColorsKeys, ExtractIfContains } from './model';

export const diluteColorToShades = <T extends AuiColorsType>(
    colorName: T,
    colorHex: string,
    colorTextHex: string
): Record<ExtractIfContains<AuiThemeColorsKeys, T>, string> => {
    if (colorHex.length !== 7 || colorTextHex.length !== 7) {
        throw Error('bad color');
    }
    const baseColorPercentageString = getColor(colorHex, 0).split(' ')[2];
    const baseColorPercentage = parseFloat(baseColorPercentageString.replace('%', ''));

    if (baseColorPercentage <= 50) {
        return {
            [`--hsl-color-${colorName}`]: getColor(colorHex, 0),
            [`--hsl-color-${colorName}-darkie`]: getColor(colorHex, 3),
            [`--hsl-color-${colorName}-darkie-always`]: getColor(colorHex, -3),
            [`--hsl-color-${colorName}-dark`]: getColor(colorHex, 7),
            [`--hsl-color-${colorName}-darken`]: getColor(colorHex, 14),
            [`--hsl-color-${colorName}-darker`]: getColor(colorHex, 21),
            [`--hsl-color-${colorName}-darkest`]: getColor(colorHex, 35),
            [`--hsl-color-${colorName}-light`]: getColor(colorHex, -5),
            [`--hsl-color-${colorName}-lighten`]: getColor(colorHex, -10),
            [`--hsl-color-${colorName}-lighter`]: getColor(colorHex, -15),
            [`--hsl-color-${colorName}-lighter-always`]: getColor(colorHex, 15),
            [`--hsl-color-${colorName}-lightest`]: getColor(colorHex, -20),
            [`--hsl-color-${colorName}-text`]: getColor(colorTextHex, 0),
        };
    } else {
        return {
            [`--hsl-color-${colorName}`]: getColor(colorHex, 0),
            [`--hsl-color-${colorName}-darkie`]: getColor(colorHex, -3),
            [`--hsl-color-${colorName}-darkie-always`]: getColor(colorHex, -3),
            [`--hsl-color-${colorName}-dark`]: getColor(colorHex, -7),
            [`--hsl-color-${colorName}-darken`]: getColor(colorHex, -14),
            [`--hsl-color-${colorName}-darker`]: getColor(colorHex, -21),
            [`--hsl-color-${colorName}-darkest`]: getColor(colorHex, -35),
            [`--hsl-color-${colorName}-light`]: getColor(colorHex, 5),
            [`--hsl-color-${colorName}-lighten`]: getColor(colorHex, 10),
            [`--hsl-color-${colorName}-lighter`]: getColor(colorHex, 15),
            [`--hsl-color-${colorName}-lighter-always`]: getColor(colorHex, 15),
            [`--hsl-color-${colorName}-lightest`]: getColor(colorHex, 20),
            [`--hsl-color-${colorName}-text`]: getColor(colorTextHex, 0),
        };
    }
};

export const getColor = (hexColor: string, darker: number, separator = '') => {
    const hsl = hexToHSL(hexColor ?? '');

    if (hsl) {
        let toDarker = hsl[2] + darker;
        if (toDarker > 100) {
            toDarker = 100;
        }
        return `${hsl[0]}deg${separator} ${hsl[1]}%${separator} ${toDarker}%`;
    }
    return '';
};
