import { LocalizationModule } from '@abp/ng.core';
import { AsyncPipe, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AuiButtonComponent } from '@ral/ui:aui/button';
import { AuiCheckboxComponent } from '@ral/ui:aui/checkbox';
import { AuiFormFieldComponent, AuiFormFieldLabelComponent, AuiFormLayoutComponent } from '@ral/ui:aui/form-layout';
import { AuiInputComponent } from '@ral/ui:aui/input';
import { TWO_FACFTOR_CODE_PLACEHGOLDER } from '@ralba-ng/core/account/domain';
import { AuthenticatorLoginStore } from '../authenticator-login.strore';

@Component({
    standalone: true,
    imports: [
        NgIf,
        AsyncPipe,
        LocalizationModule,
        ReactiveFormsModule,
        AuiFormFieldComponent,
        AuiCheckboxComponent,
        AuiButtonComponent,
        AuiInputComponent,
        AuiFormFieldLabelComponent,
        AuiFormLayoutComponent,
    ],
    selector: 'core-account-authenticator-login-form',
    providers: [AuthenticatorLoginStore],
    template: `
        <form [formGroup]="form" (ngSubmit)="handleFormSubmit()">
            <aui-form-layout class="flex flex-col gap-4 pb-4">
                <aui-form-field>
                    <aui-form-field-label [label]="'AppthorAccount::TwoFactorCode' | abpLocalization" />
                    <aui-input
                        type="text"
                        [formControl]="form.controls.twoFactorCode"
                        [placeholder]="twoFactorCodePlaceholder"
                    />
                </aui-form-field>

                <aui-form-field>
                    <aui-checkbox
                        [formControl]="form.controls.rememberMachine"
                        [checkboxLabel]="'AppthorAccount::RememberMachine' | abpLocalization"
                    />
                </aui-form-field>

                <aui-button
                    [color]="'primary'"
                    type="submit"
                    [disabled]="form.invalid"
                    [label]="'AppthorAccount::Login' | abpLocalization"
                    [extend]="true"
                    [size]="'40'"
                />
            </aui-form-layout>
        </form>
    `,
})
export class AuthenticatorLoginFormComponent {
    public readonly twoFactorCodePlaceholder = inject(TWO_FACFTOR_CODE_PLACEHGOLDER);
    private readonly state = inject(AuthenticatorLoginStore);
    public readonly form = this.state.form;

    public handleFormSubmit() {
        this.state.handleFormSubmit();
    }
}
