import { inject } from '@angular/core';
import { ConfigStateService } from '@abp/ng.core';
import { filter, first } from 'rxjs';

export function getEnumValues(obj: object): Array<string> {
    return Object.values(obj);
}

export function getEnumKeys(obj: object): Array<string> {
    return Object.keys(obj);
}

export function appInitializerConfigStateAwaiter(fn: () => void) {
    const configState = inject(ConfigStateService);
    return () =>
        new Promise((resolve) => {
            configState
                .getAll$()
                .pipe(
                    filter((f) => Object.keys(f).length !== 0),
                    first()
                )
                .subscribe(() => {
                    fn();
                    resolve(null);
                });
        });
}
