import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { injectAuiThemeStore } from '@ral/ui:aui:theme:basic';

@Component({
    standalone: true,
    imports: [RouterModule],
    selector: 'ralba-ng-root',
    template: ` <router-outlet></router-outlet>`,
    styles: [''],
})
export class AppComponent {
    theme = injectAuiThemeStore();
    constructor() {
        // this.theme.registerTheme('LightMode', TaxPrimaLightMode);
        // this.theme.registerTheme('DarkMode', TaxPrimaDarkMode);
    }
}
