import { LocalizationModule } from '@abp/ng.core';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LOGIN_URL_LINK } from '@ralba-ng/core/account/domain';

@Component({
    selector: 'core-account-ui-back-to-login',
    standalone: true,
    imports: [CommonModule, RouterLink, LocalizationModule],
    template: `<a
        [routerLink]="loginUrl"
        queryParamsHandling="merge"
        class="font-medium text-primary-600 hover:underline dark:text-primary-500"
        >{{ 'AppthorAccount::BackToLogin' | abpLocalization }}
    </a>`,
})
export class CoreAccountUiBackToLoginComponent {
    public readonly loginUrl = inject(LOGIN_URL_LINK);
}
