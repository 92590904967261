import { Routes } from '@angular/router';
import { CoreAccountForgotPasswordComponent } from './core-auth-forgot-password.component';
import { CoreAccountForgotPasswordFormComponent } from './components/forgot-password-form.component';
import { CoreAccountForgotPasswordLinkSentComponent } from './components/forgot-password-link-sent.component';
import { CoreAuthResetPasswordComponent } from './core-auth-reset-password.component';
import { CoreAccountResetPasswordFormComponent } from './components/reset-password-form.component';
import { CoreAccountResetPasswordSuccessComponent } from './components/reset-password-success.component';
import { canActivatePasswordReset } from './guards/reset-password.guard';

export const forgotPasswordRoutes = (): Routes => [
    {
        path: '',
        component: CoreAccountForgotPasswordComponent,
        children: [
            { path: '', component: CoreAccountForgotPasswordFormComponent },
            { path: 'link-sent', component: CoreAccountForgotPasswordLinkSentComponent },
        ],
    },
];

export const resetPasswordRoutes = (): Routes => [
    {
        path: '',
        component: CoreAuthResetPasswordComponent,
        canActivate: [canActivatePasswordReset],
        children: [
            { path: '', component: CoreAccountResetPasswordFormComponent },
            { path: 'success', component: CoreAccountResetPasswordSuccessComponent },
        ],
    },
];
