import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    ContentChild,
    inject,
    input,
    signal,
    TemplateRef,
    ViewEncapsulation,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AuiBadgeComponent } from '@ral/ui:aui/badge';
import { AuiButtonComponent } from '@ral/ui:aui/button';
import { AuiIcon } from '@ral/ui:aui/icons';
import { AuiListBoxComponent } from '@ral/ui:aui/listbox';
import { AuiTooltipDirective } from '@ral/ui:aui/tooltip';
import { AuiColor, AuiOption } from '@ral/ui:aui/utils';
import { injectAuiTranslationDictionary } from '@ral/utils:core/ui';
import { NgxControlValueAccessor } from 'ngxtension/control-value-accessor';
import { hostBinding } from 'ngxtension/host-binding';

@Component({
    selector: 'aui-select',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [
        ReactiveFormsModule,
        CommonModule,
        OverlayModule,
        AuiListBoxComponent,
        AuiBadgeComponent,
        AuiButtonComponent,
        AuiTooltipDirective,
    ],
    hostDirectives: [{ directive: NgxControlValueAccessor, inputs: ['value'], outputs: ['valueChange'] }],
    template: `
        <div [class]="classes()" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
            <!-- 26px -->
            <div
                data-testid="select-origin"
                class="flex-grow flex-shrink overflow-hidden cursor-pointer self-stretch py-0.5 px-2"
                (click)="onClick()"
            >
                @if (selectedOption()) {
                    @if (useBadge()) {
                        <div class="py-0.5">
                            <!-- 22px + 4px -->
                            <aui-badge
                                [color]="selectedColor() ?? defaultBadgeColor()"
                                [rounded]="true"
                                [buttonStyle]="'outline'"
                                [label]="selectedLabel()"
                                [icon]="selectedIcon()"
                            >
                            </aui-badge>
                        </div>
                    } @else {
                        <!-- 20px -->
                        <span
                            class="aui-font-regular-16 leading-6 block text-nowrap py-px text-ellipsis overflow-hidden"
                        >
                            {{ selectedLabel() }}
                        </span>
                    }
                } @else {
                    <!-- 20px -->
                    <span class="placeholder aui-font-regular-16 leading-6 block py-px">
                        {{ placeholder() }}
                    </span>
                }
            </div>
            @if (showClearButton() && canUserInteract()) {
                <div class="flex-none self-center" (click)="clearOption()">
                    <aui-button [icon]="'times'" [buttonStyle]="'text'" tooltip="Clear control"></aui-button>
                </div>
            }
            @if (showSelectionArrow() && canUserInteract()) {
                <div class="flex-none self-center" (click)="onClick()">
                    <aui-button [icon]="selectionArrowIcon()" [buttonStyle]="'text'"></aui-button>
                </div>
            }

            <ng-template
                cdkConnectedOverlay
                [cdkConnectedOverlayOrigin]="trigger"
                [cdkConnectedOverlayOpen]="isOpen"
                [cdkConnectedOverlayHasBackdrop]="true"
                [cdkConnectedOverlayBackdropClass]="'backdrop'"
                (backdropClick)="onBlur()"
            >
                <div class="backdrop mt-2  w-full shadow-xl min-w-48 rounded aui-font-regular-14">
                    <aui-list-box
                        [readonly]="readonly()"
                        [value]="cva.value$()"
                        [options]="options() || []"
                        [filtering]="filtering()"
                        [allowNull]="allowNull()"
                        [emptyLabel]="emptyLabel()"
                        (valueChange)="itemClick($event)"
                    >
                        @if (emptyTemp) {
                            <ng-template #emptyTemplate>
                                <ng-container *ngTemplateOutlet="emptyTemp"></ng-container>
                            </ng-template>
                        }
                    </aui-list-box>
                </div>
            </ng-template>
        </div>
    `,
})
export class AuiSelectComponent {
    isOpen = false;
    translations = injectAuiTranslationDictionary();

    options = input<AuiOption[] | null>(null);
    placeholder = input<string | null>(this.translations.selectPlaceholder());
    filtering = input<boolean>(false);
    readonly = input<boolean>(false);
    border = input<boolean>(true);
    useBadge = input<boolean>(false);
    allowNull = input<boolean>(true);
    showSelectionArrow = input<boolean>(true);
    selectionArrowIcon = input<AuiIcon>('chevron-down');
    showClearButton = input<boolean>(false);
    defaultBadgeColor = input<AuiColor>('gray');

    emptyLabel = input<string>(this.translations.noItems());
    @ContentChild('emptyTemplate', { read: TemplateRef }) emptyTemp?: TemplateRef<unknown>;
    calculatedHostClasses = hostBinding('attr.class', signal('w-full'));
    protected cva = inject<NgxControlValueAccessor<AuiOption['value'] | null>>(NgxControlValueAccessor);
    selectedOption = computed(() => {
        const selectedValue = this.cva.value$() ?? this.cva.value;
        const options = this.options();
        if (selectedValue && options) {
            return options.find((option) => option.value === selectedValue);
        }
        return null;
    });
    selectedLabel = computed(() => {
        return this.selectedOption()?.label ?? null;
    });
    selectedIcon = computed(() => {
        return this.selectedOption()?.icon ?? null;
    });
    selectedColor = computed(() => {
        return this.selectedOption()?.color;
    });
    canUserInteract = computed(() => {
        return !(this.readonly() || this.cva.disabled$());
    });
    classes = computed(() => {
        const disabled = this.cva.disabled;

        let tailwindClasses = [
            'flex',
            'flex-nowrap',
            'rounded',
            'aui-font-regular-14',
            'transition-all',
            'w-full',
            'min-h-8',
            'gap-px',
            'pr-px',
        ];

        // disabled opacity + remove active, focus and hover states
        if (this.border()) {
            tailwindClasses.push('border border-surface-background-darker hover:border-surface-background-darken');
        }

        if (disabled) {
            tailwindClasses.push('bg-surface-background-dark', 'border-surface-background');
            tailwindClasses = tailwindClasses.filter(
                (p) => !(p.includes('hover') || p.includes('active') || p.includes('focus'))
            );
        }

        return tailwindClasses.join(' ');
    });

    itemClick(value: AuiOption['value'] | null) {
        if (this.canUserInteract()) {
            if (!Array.isArray(value)) {
                this.cva.value = value;
            }
            this.isOpen = false;
        }
    }

    clearOption() {
        if (this.canUserInteract()) {
            this.cva.value = null;
        }
    }

    onClick() {
        if (this.canUserInteract()) {
            this.isOpen = true;
        }
    }

    onBlur() {
        this.isOpen = false;
        this.cva.markAsTouched();
    }
}
