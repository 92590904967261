import { Environment } from '@abp/ng.core';

const baseUrl = 'https://localhost:44316';

export const environment = {
    production: true,
    application: {
        baseUrl,
        name: 'NgAccount',
        logoUrl: 'assets/logo.svg',
    },
    apis: {
        default: {
            url: '',
        },
    },
    remoteEnv: {
        mergeStrategy: 'deepmerge',
        url: 'config/appsettings.json',
    },
} as Environment;
