import { InjectionToken } from '@angular/core';

export const FORGOT_PASSWORD_LINK_TOKEN = new InjectionToken<string[]>('Forgot password link', {
    providedIn: 'root',
    factory: () => ['/', 'forgot-password'],
});

export const LOGIN_URL_LINK = new InjectionToken<string[]>('Lint to login', {
    providedIn: 'root',
    factory: () => ['/', 'login'],
});

export const USERNAME_OR_EMAIL_ADDRESS_PLACEHOLDER = new InjectionToken<string>('Lint to login', {
    providedIn: 'root',
    factory: () => 'yourname@company.com',
});

export const EMAIL_ADDRESS_PLACEHOLDER = new InjectionToken<string>('Lint to login', {
    providedIn: 'root',
    factory: () => 'yourname@company.com',
});

export const USERNAME_PLACEHOLDER = new InjectionToken<string>('Lint to login', {
    providedIn: 'root',
    factory: () => 'your nick name',
});

export const PASSWORD_PLACEHOLDER = new InjectionToken<string>('Lint to login', {
    providedIn: 'root',
    factory: () => '*******',
});

export const TWO_FACFTOR_CODE_PLACEHGOLDER = new InjectionToken<string>('Lint to login', {
    providedIn: 'root',
    factory: () => 'XXXXXX',
});

export const RECOVERY_CODE_PLACEHGOLDER = new InjectionToken<string>('Lint to login', {
    providedIn: 'root',
    factory: () => 'XXXXX-XXXXX',
});
