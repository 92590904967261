import { LocalizationModule } from '@abp/ng.core';
import { AsyncPipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthenticatorLoginStore } from './authenticator-login.strore';
import { AuthenticatorLoginFormComponent } from './components/authenticator-login-form.component';

@Component({
    standalone: true,
    imports: [NgIf, AsyncPipe, LocalizationModule, ReactiveFormsModule, AuthenticatorLoginFormComponent],
    selector: 'core-account-authenticator-login',
    providers: [AuthenticatorLoginStore],
    template: `
        <ng-container *ngIf="state.vm$ | async as vm">
            <small *ngIf="vm.invalidAuthenticatorCodeResult" class="p-error">{{
                'AppthorAccount::InvalidAuthenticatorCode' | abpLocalization
            }}</small>
            <small *ngIf="vm.userLockedResult" class="p-error">{{
                'AppthorAccount::UserLockedOutMessage' | abpLocalization
            }}</small>

            <core-account-authenticator-login-form />
        </ng-container>
    `,
})
export class AuthenticatorLoginComponent {
    constructor(public readonly state: AuthenticatorLoginStore) {}
}
