import { AuiColorsType, AuiThemeColorsSet, diluteColorToShades } from '@ral/utils:core/theme';
import { createInjectionToken } from 'ngxtension/create-injection-token';

export type AppLevelTheme = {
    themeName: string;
    themeKey: string;
    defaultPriorityIndex: number;
    theme: AuiThemeColorsSet;
};

export const [injectAppLevelThemes, provideAppLevelTheme, AppLevelTheme] = createInjectionToken(
    () => {
        return {} as AppLevelTheme;
    },
    {
        multi: true,
    }
);

export type ThemeTokenConfiguration = {
    variableOverwrite?: Partial<AuiThemeColorsSet>;
    colorsOverwrite?: {
        colorName: AuiColorsType;
        colorHex: string;
        colorTextHex: string;
    }[];
};

export const SolveColorOverWrites = (
    originalTheme: AuiThemeColorsSet,
    overwrites?: ThemeTokenConfiguration
): AuiThemeColorsSet => {
    let theme = { ...originalTheme };
    if (overwrites?.colorsOverwrite) {
        theme = {
            ...theme,
            ...overwrites.colorsOverwrite.reduce((prev, curr) => {
                const { colorName, colorHex, colorTextHex } = curr;
                return {
                    ...prev,
                    ...diluteColorToShades(colorName, colorHex, colorTextHex),
                };
            }, {} as Partial<AuiThemeColorsSet>),
        };
    }
    if (overwrites?.variableOverwrite) {
        theme = {
            ...theme,
            ...overwrites.variableOverwrite,
        };
    }
    return theme;
};
