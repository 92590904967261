import { AnyZodObject, z } from 'zod';
import { O } from 'ts-toolbelt';

export abstract class SchemaService<TSchema extends AnyZodObject> {
    protected constructor(protected readonly schema: TSchema) {}

    public validate(model: z.infer<TSchema>): z.SafeParseReturnType<O.Object, z.infer<TSchema>> {
        return this.schema.safeParse(model);
    }

    public validateAsync(model: z.infer<TSchema>): Promise<z.SafeParseReturnType<O.Object, z.infer<TSchema>>> {
        return this.schema.safeParseAsync(model);
    }
}
