import { LocalizationModule } from '@abp/ng.core';
import { AsyncPipe, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FORGOT_PASSWORD_LINK_TOKEN } from '@ralba-ng/core/account/domain';
import { MessageModule } from 'primeng/message';
import { LocalLoginFormComponent } from './local-login-form/local-login-form.component';
import { LocalLoginStore } from './local-login.store';

@Component({
    standalone: true,
    imports: [NgIf, AsyncPipe, LocalLoginFormComponent, LocalizationModule, MessageModule, RouterLink],
    selector: 'core-account-local-login',
    providers: [LocalLoginStore],
    template: `
        <ng-container *ngIf="vm$ | async as vm">
            <ng-container *ngIf="vm.isLocalLoginEnabled">
                <small *ngIf="vm.invalidUserNameOrPasswordResult" class="p-error">{{
                    'AppthorAccount::InvalidUserNameOrPassword' | abpLocalization
                }}</small>
                <small *ngIf="vm.userLockedResult" class="p-error">{{
                    'AppthorAccount::UserLockedOutMessage' | abpLocalization
                }}</small>
                <core-account-local-login-form />
                <a [routerLink]="forgotPasswordLink" class="text-sm font-medium">{{
                    'AppthorAccount::ForgotPassword' | abpLocalization
                }}</a>
            </ng-container>
        </ng-container>
    `,
})
export class LocalLoginComponent {
    public readonly forgotPasswordLink = inject(FORGOT_PASSWORD_LINK_TOKEN);
    private readonly state = inject(LocalLoginStore);
    public readonly vm$ = this.state.vm$;
}
