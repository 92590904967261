import { Type } from '@angular/core';
import { Route } from '@angular/router';
import { AuthComponent } from './auth.component';
import { LoginAuthenticatorPageComponent } from './login/login-authenticator.component';
import { LoginRecoveryCodePageComponent } from './login/login-recovery-code.component';
import { LoginPageComponent } from './login/login.component';
import { RegisterPageComponent } from './register/register.component';

import { forgotPasswordRoutes, resetPasswordRoutes } from '@ralba-ng/core/account/feature-reset-password';
import { ResetPasswordPageComponent } from './reset-password/reset-password-page.component';

export function authRoutes(layout: Type<unknown>): Route[] {
    return [
        {
            path: '',
            component: layout,
            providers: [
                /*{
          provide: RESET_PASSWORD_LINK_TOKEN,
          useValue: ['/', 'passwrod-reset'],
        }*/
            ],
            children: [
                {
                    path: '',
                    redirectTo: 'login',
                    pathMatch: 'full',
                },
                {
                    path: 'login',
                    component: AuthComponent,
                    children: [
                        { path: '', component: LoginPageComponent },
                        { path: 'authenticator', component: LoginAuthenticatorPageComponent },
                        { path: 'recovery-code', component: LoginRecoveryCodePageComponent },
                    ],
                },
                {
                    path: 'register',
                    children: [{ path: '', component: RegisterPageComponent }],
                },
                {
                    path: 'forgot-password',
                    component: ResetPasswordPageComponent,
                    children: forgotPasswordRoutes(),
                },
                {
                    path: 'reset-password',
                    component: ResetPasswordPageComponent,
                    children: resetPasswordRoutes(),
                },
            ],
        },
    ];
}
