import { Component } from '@angular/core';
import { LocalizationModule } from '@abp/ng.core';

@Component({
    standalone: true,
    selector: 'core-account-forgot-password',
    imports: [LocalizationModule],
    template: `
        <span>
            {{ 'AppthorAccount::PasswordResetMailSentMessage' | abpLocalization }}
        </span>
    `,
})
export class CoreAccountForgotPasswordLinkSentComponent {}
