import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, ElementRef, HostListener, OnInit, input } from '@angular/core';
import { createInjectionToken } from 'ngxtension/create-injection-token';

import { TooltipComponent } from './aui-tooltip.component';

export type Position = 'top' | 'bottom' | 'left' | 'right';

function defaultOptionsFactory() {
    return { position: 'top' as Position };
}

export const [injectDefaultTooltipOptions, provideDefaultTooltipOptions, AUI_TOOLTIP_DEFAULT_OPTIONS] =
    createInjectionToken(defaultOptionsFactory);

@Directive({
    standalone: true,
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[tooltip]',
})
export class AuiTooltipDirective implements OnInit {
    tooltipDefaults = injectDefaultTooltipOptions();
    overlayRef: OverlayRef | undefined;
    positionStrategy = this.overlay.position().flexibleConnectedTo(this.el);

    tooltip = input<string | undefined | null>();
    showUntilWidth = input<number | undefined | null>();
    position = input(null as 'top' | 'bottom' | 'left' | 'right' | null);
    _position: 'top' | 'bottom' | 'left' | 'right' = 'top';

    constructor(
        private el: ElementRef,
        private overlay: Overlay,
        private platform: Platform
    ) {}

    @HostListener('mouseenter') onMouseEnter() {
        if (!this.platform.IOS && !this.platform.ANDROID) {
            this.openTooltip();
        }
    }

    @HostListener('mouseleave') onMouseLeave() {
        if (!this.platform.IOS && !this.platform.ANDROID) {
            this.closeTooltip();
        }
    }

    openTooltip() {
        const showUntilWidth = this.showUntilWidth();
        if (showUntilWidth && showUntilWidth < this.el.nativeElement.offsetWidth) return;
        this.overlayRef = this.overlay.create({ positionStrategy: this.positionStrategy });
        const Tooltip = new ComponentPortal(TooltipComponent);
        const tooltipRef = this.overlayRef.attach(Tooltip);
        tooltipRef.instance.tooltip = this.tooltip();
    }

    closeTooltip() {
        this.overlayRef?.dispose();
    }

    ngOnInit() {
        const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
        if (isTouchDevice) {
            // If it's a touch device, use touch events
            this.el.nativeElement.addEventListener('touchstart', () => {
                console.log('open');
                this.openTooltip();
            });
            this.el.nativeElement.addEventListener('touchend', () => {
                console.log('close');
                this.closeTooltip();
            });
        }
        if (this.tooltipDefaults) this._position = this.position() || this.tooltipDefaults.position;
        if (this._position === 'top') {
            this.positionStrategy = this.overlay
                .position()
                .flexibleConnectedTo(this.el)
                .withPositions([
                    {
                        originX: 'center',
                        originY: 'top',
                        overlayX: 'center',
                        overlayY: 'top',
                        offsetX: 0,
                        offsetY: -35,
                    },
                    {
                        originX: 'center',
                        originY: 'bottom',
                        overlayX: 'center',
                        overlayY: 'bottom',
                        offsetX: 0,
                        offsetY: 35,
                    },
                    {
                        originX: 'end',
                        originY: 'center',
                        overlayX: 'start',
                        overlayY: 'bottom',
                        offsetX: 20,
                        offsetY: 10,
                    },
                    {
                        originX: 'end',
                        originY: 'center',
                        overlayX: 'start',
                        overlayY: 'bottom',
                        offsetX: 20,
                        offsetY: 10,
                    },
                ]);
        } else if (this._position === 'bottom') {
            this.positionStrategy = this.overlay
                .position()
                .flexibleConnectedTo(this.el)
                .withPositions([
                    {
                        originX: 'center',
                        originY: 'bottom',
                        overlayX: 'center',
                        overlayY: 'bottom',
                        offsetX: 0,
                        offsetY: 35,
                    },
                    {
                        originX: 'center',
                        originY: 'top',
                        overlayX: 'center',
                        overlayY: 'top',
                        offsetX: 0,
                        offsetY: -35,
                    },
                    {
                        originX: 'end',
                        originY: 'center',
                        overlayX: 'start',
                        overlayY: 'bottom',
                        offsetX: 20,
                        offsetY: 10,
                    },
                    {
                        originX: 'start',
                        originY: 'center',
                        overlayX: 'end',
                        overlayY: 'bottom',
                        offsetX: -20,
                        offsetY: 10,
                    },
                ]);
        } else if (this._position === 'right') {
            this.positionStrategy = this.overlay
                .position()
                .flexibleConnectedTo(this.el)
                .withPositions([
                    {
                        originX: 'end',
                        originY: 'center',
                        overlayX: 'start',
                        overlayY: 'bottom',
                        offsetX: 20,
                        offsetY: 10,
                    },
                    {
                        originX: 'center',
                        originY: 'bottom',
                        overlayX: 'center',
                        overlayY: 'bottom',
                        offsetX: 0,
                        offsetY: 35,
                    },
                    {
                        originX: 'center',
                        originY: 'top',
                        overlayX: 'center',
                        overlayY: 'top',
                        offsetX: 0,
                        offsetY: -35,
                    },
                    {
                        originX: 'start',
                        originY: 'center',
                        overlayX: 'end',
                        overlayY: 'bottom',
                        offsetX: -20,
                        offsetY: 10,
                    },
                ]);
        } else if (this._position === 'left') {
            this.positionStrategy = this.overlay
                .position()
                .flexibleConnectedTo(this.el)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'center',
                        overlayX: 'end',
                        overlayY: 'bottom',
                        offsetX: -20,
                        offsetY: 10,
                    },
                    {
                        originX: 'center',
                        originY: 'top',
                        overlayX: 'center',
                        overlayY: 'top',
                        offsetX: 0,
                        offsetY: -32,
                    },
                    {
                        originX: 'center',
                        originY: 'bottom',
                        overlayX: 'center',
                        overlayY: 'bottom',
                        offsetX: 0,
                        offsetY: 32,
                    },
                    {
                        originX: 'end',
                        originY: 'center',
                        overlayX: 'start',
                        overlayY: 'bottom',
                        offsetX: 32,
                        offsetY: 10,
                    },
                ]);
        }
    }
}
