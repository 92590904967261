import { ComponentStore, OnStoreInit } from '@ngrx/component-store';
import { ActivatedRoute, Router } from '@angular/router';
import { inject } from '@angular/core';
import { exhaustMap, first, iif, map, Observable, of, pipe, switchMap, tap } from 'rxjs';
import { z } from 'zod';

export abstract class AbstractQueryToStateSynchronizer<TSchema extends z.AnyZodObject>
    extends ComponentStore<never>
    implements OnStoreInit
{
    protected abstract readonly Schema: TSchema;
    protected abstract readonly queryName: string;

    protected readonly router = inject(Router);
    protected readonly activatedRoute = inject(ActivatedRoute);
    protected abstract selectState: Observable<z.infer<TSchema>>;
    protected readonly queryParams$ = this.activatedRoute.queryParams.pipe(
        first(),
        exhaustMap((query) =>
            iif(() => Boolean(query[this.queryName]), of(this.tryParseQuery(query[this.queryName])), of(null))
        )
    );
    protected readonly syncQuery = this.effect<void>(
        pipe(
            switchMap(() =>
                this.queryParams$.pipe(tap((schema) => (schema ? this.syncToState(schema) : this.resetState())))
            ),
            switchMap(() =>
                this.selectState.pipe(
                    map((state) => this.Schema.safeParse(state)),
                    switchMap((parse) => {
                        if (parse.success)
                            return this.router.navigate([], {
                                replaceUrl: true,
                                queryParams: {
                                    [this.queryName]: btoa(encodeURIComponent(JSON.stringify(parse.data))),
                                },
                            });
                        console.error(parse.error);
                        return of(null);
                    })
                )
            )
        )
    );

    ngrxOnStoreInit(): void {
        this.syncQuery();
    }

    protected abstract syncToState(schema: z.infer<TSchema>): void;

    protected abstract resetState(): void;

    protected tryParseQuery(query: string) {
        if (!query) return null;
        const parse = this.Schema.safeParse(JSON.parse(decodeURIComponent(atob(query))));
        return parse.success ? parse.data : null;
    }
}
