import { inject, Injectable } from '@angular/core';
import { exhaustMap, map, Observable } from 'rxjs';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { ConfigStateService } from '@abp/ng.core';
import { ConfigConst } from '../const/config';
import { AuthStore } from '../services/auth.state';
import { RegisterHttpService } from '../infrastructure/register-http.service';
import { RegisterModel } from '../entities/register';
import { LoginResultType } from '../entities/login-result-types';
import { ErrorResponse } from '@ralba-ng/shared/util-core';

export interface RegisterState {
    error: ErrorResponse | null;
}

@Injectable({ providedIn: 'root' })
export class RegisterFacade extends ComponentStore<RegisterState> {
    public readonly error$ = this.select((s) => s.error);
    private readonly config = inject(ConfigStateService);
    public readonly IsSelfRegistrationEnabled$ = this.config
        .getSetting$(ConfigConst.IsSelfRegistrationEnabled)
        .pipe(map((value) => value?.toLowerCase() !== 'false'));
    private readonly authState = inject(AuthStore);

    private readonly registerHttpService = inject(RegisterHttpService);

    private readonly handleRegister = this.effect(($: Observable<RegisterModel>) =>
        $.pipe(
            exhaustMap((input) =>
                this.registerHttpService.register(input).pipe(
                    tapResponse({
                        next: () => {
                            this.authState.patchState({
                                loginResult: {
                                    result: LoginResultType.Success,
                                    description: '',
                                },
                            });
                        },
                        error: (error: ErrorResponse) => {
                            this.patchState({ error });
                        },
                    })
                )
            )
        )
    );

    constructor() {
        super({ error: null });
    }

    public register(input: RegisterModel) {
        this.handleRegister(input);
    }
}
