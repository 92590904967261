import { ComponentStore } from '@ngrx/component-store';
import { inject, Injectable } from '@angular/core';
import { ExternalLoginFacade, ExternalProvider } from '@ralba-ng/core/account/domain';

@Injectable()
export class CoreAccountExternalLoginStore extends ComponentStore<never> {
    private readonly externalLoginFacade = inject(ExternalLoginFacade);

    public readonly vm$ = this.select({
        providers: this.externalLoginFacade.providers$,
        isExternalProviderAvailable: this.externalLoginFacade.isExternalProviderAvailable$,
        isLocalLoginEnabled: this.externalLoginFacade.isLocalLoginEnabled$,
    });

    public loginWithProvider(provider: ExternalProvider) {
        this.externalLoginFacade.loginWithExternalProvider(provider);
    }
}
