import { LocalizationModule } from '@abp/ng.core';
import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { AuiButtonComponent } from '@ral/ui:aui/button';
import { AuiFormFieldComponent, AuiFormFieldLabelComponent, AuiFormLayoutComponent } from '@ral/ui:aui/form-layout';
import { AuiInputComponent } from '@ral/ui:aui/input';
import { PASSWORD_PLACEHOLDER, ResetPasswordFacade } from '@ralba-ng/core/account/domain';
import { Observable, map, tap, withLatestFrom } from 'rxjs';

@Component({
    standalone: true,
    selector: 'core-account-forgot-password',
    imports: [
        ReactiveFormsModule,
        LocalizationModule,
        AuiButtonComponent,
        AuiButtonComponent,
        AuiFormLayoutComponent,
        AuiFormFieldLabelComponent,
        AuiInputComponent,
        AuiFormFieldComponent,
    ],
    template: `
        <form class="flex flex-col space-y-4 md:space-y-6" [formGroup]="form" (ngSubmit)="handleSubmit$()">
            <aui-form-layout>
                <aui-form-field>
                    <aui-form-field-label [label]="'AppthorAccount::ResetPassword_Information' | abpLocalization" />

                    <aui-input
                        type="password"
                        [formControl]="form.controls.password"
                        [placeholder]="passwordPlaceholder"
                    />
                </aui-form-field>
                <aui-button
                    type="submit"
                    [label]="'AppthorAccount::ResetMyPassword' | abpLocalization"
                    [disabled]="form.invalid"
                    [extend]="true"
                    [size]="'40'"
                    [color]="'primary'"
                />
            </aui-form-layout>
        </form>
    `,
})
export class CoreAccountResetPasswordFormComponent extends ComponentStore<never> {
    public readonly form = new FormGroup({
        password: new FormControl('', {
            nonNullable: true,
            validators: [Validators.required],
        }),
    });

    public readonly passwordPlaceholder = inject(PASSWORD_PLACEHOLDER);
    private readonly facade = inject(ResetPasswordFacade);

    private readonly activatedRoute = inject(ActivatedRoute);

    public readonly handleSubmit$ = this.effect(($: Observable<void>) =>
        $.pipe(
            withLatestFrom(
                this.activatedRoute.queryParams.pipe(
                    map((params) => {
                        return {
                            userId: params['UserId'],
                            resetToken: params['resetToken'],
                        };
                    })
                )
            ),
            map(([, params]) => ({ params })),
            tap(({ params }) => {
                this.facade.resetPassword({
                    password: this.form.controls.password.value,
                    resetToken: params.resetToken,
                    userId: params.userId,
                });
            })
        )
    );
}
