import { NgTemplateOutlet } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { hostBinding } from 'ngxtension/host-binding';

/**
 * Flex / Grid around form field with possibility of overwrite default behavior
 */
@Component({
    standalone: true,
    selector: 'aui-form-layout',
    imports: [NgTemplateOutlet],
    template: ` <ng-content></ng-content> `,
})
export class AuiFormLayoutComponent {
    className = input<string | null>(null);

    classes = hostBinding(
        'attr.class',
        computed(() => {
            const className = this.className();
            return className ? className : 'flex flex-col gap-4 pb-4';
        })
    );
}
