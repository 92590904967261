import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AuiIcon, AuiIconComponent } from '@ral/ui:aui/icons';
import { NgxControlValueAccessor } from 'ngxtension/control-value-accessor';

@Component({
    selector: 'aui-checkbox',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [ReactiveFormsModule, CommonModule, AuiIconComponent],
    hostDirectives: [{ directive: NgxControlValueAccessor, outputs: ['valueChange'] }],
    template: `
        <label
            data-testid="checkbox"
            class="flex flex-nowrap gap-2 items-center cursor-pointer w-full mb-1"
            (click)="toggleSelection()"
        >
            <div [class]="checkboxClasses()">
                @if (cva.value$()) {
                    <aui-icon data-testid="check-icon" [icon]="checkIcon()"></aui-icon>
                }
            </div>
            <span class="flex-1 aui-font-regular-14 aui-font-regular-14">
                {{ checkboxLabel() }}
            </span>
        </label>
    `,
})
export class AuiCheckboxComponent {
    protected cva = inject<NgxControlValueAccessor<boolean>>(NgxControlValueAccessor);

    size = input<'16' | '20' | '24'>('24');
    checkIcon = input<AuiIcon>('check');
    readonly = input<boolean>(false);
    checkboxLabel = input<string | null>(null);

    toggleSelection() {
        if (this.readonly() || this.cva.disabled) {
            return;
        }
        this.cva.value = !this.cva.value;
    }

    checkboxClasses = computed(() => {
        const size = this.size();
        const value = this.cva.value$();
        const readonly = this.readonly();
        const disabled = this.cva.disabled$();
        const tailwindClasses = [
            'flex-none ',
            'flex',
            'justify-center',
            'items-center',
            'transition-all',
            'rounded',
            'border',
            'cursor-pointer',
            'text-primary-text',
        ];

        if (size === '16') {
            tailwindClasses.push('w-4 h-4 aui-font-regular-10');
        } else if (size === '20') {
            tailwindClasses.push('w-5 h-5 aui-font-regular-12');
        } else if (size === '24') {
            tailwindClasses.push('w-6 h-6 aui-font-regular-14');
        }

        if (value) {
            tailwindClasses.push('bg-primary border-primary');
            if (disabled || readonly) {
                tailwindClasses.push('opacity-60');
            }
        } else {
            tailwindClasses.push('border-surface-background-darker');
            if (disabled || readonly) {
                tailwindClasses.push('bg-surface-background');
            } else {
                tailwindClasses.push('bg-surface-background-lightest');
            }
        }

        return tailwindClasses.join(' ');
    });
}
