import { NSwagException } from '@ral/utils:core/http';
import { BusinessException, NotFoundException } from '@ral/utils:core/exceptions';
import { isRemoteServiceErrorInfo } from './remote-service-error';

export function handleNSwagException(exception: object): never {
    if (!NSwagException.isApiException(exception)) throw exception;
    if (!isRemoteServiceErrorInfo(exception.result)) throw exception;

    const { error } = exception.result;

    switch (exception.status) {
        case 404:
            throw new NotFoundException(error.message, error.details, error.data);
        case 403:
            throw new BusinessException(error.message, error.code, error.details, error.data);
        default:
            throw exception.result;
    }
}

export function handleOpenApiException(exception: object): never {
    if (!('name' in exception && exception.name === 'HttpErrorResponse' && 'status' in exception)) throw exception;
    if (!('error' in exception && !!exception.error)) throw exception;
    if (!isRemoteServiceErrorInfo(exception.error)) throw exception;
    const { error } = exception.error;

    switch (exception.status) {
        case 404:
            throw new NotFoundException(error.message, error.details, error.data);
        case 403:
            throw new BusinessException(error.message, error.code, error.details, error.data);
        default:
            throw error;
    }
}
