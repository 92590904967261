import { inject, Injectable } from '@angular/core';
import { exhaustMap, map, Observable, tap } from 'rxjs';
import { ConfigStateService } from '@abp/ng.core';
import { ConfigConst } from '../const/config';
import { LoginWithPassword } from '../entities/login-with-password';
import { AuthStore } from '../services/auth.state';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { LoginHttpService } from '../infrastructure/login-http.service';

@Injectable({ providedIn: 'root' })
export class LocalLoginFacade extends ComponentStore<never> {
    private readonly configState = inject(ConfigStateService);
    public readonly isLocalLoginEnabled$ = this.configState
        .getSetting$(ConfigConst.isLocalLoginEnabled)
        .pipe(map((value) => value?.toLowerCase() !== 'false'));
    private readonly authState = inject(AuthStore);
    public readonly loginResult$ = this.authState.loginResult$;
    private readonly loginHttpService = inject(LoginHttpService);

    private readonly handleLoginWithPassword$ = this.effect(($: Observable<LoginWithPassword>) =>
        $.pipe(
            tap((input) => this.authState.patchState({ rememberMe: input.rememberMe })),
            exhaustMap((input) =>
                this.loginHttpService.login(input).pipe(
                    tapResponse({
                        next: (loginResult) => {
                            this.authState.updateLoginResult(loginResult);
                        },
                        error: (error) => {
                            console.error(error);
                        },
                    })
                )
            )
        )
    );

    public login(input: LoginWithPassword) {
        this.handleLoginWithPassword$(input);
    }
}
