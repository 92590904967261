import { z } from 'zod';

export const ErrorResponse = z.object({
    __discriminator: z.literal('ErrorResponse'),
    code: z.string().nullish(),
    message: z.string().nullish(),
    details: z.string().nullish(),
});

export type ErrorResponse = z.infer<typeof ErrorResponse>;

export function throwErrorResponse(err: Omit<ErrorResponse, '__discriminator'>): never {
    console.log(err);
    throw {
        ...err,
        __discriminator: 'ErrorResponse',
    } as ErrorResponse;
}
