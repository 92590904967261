import { LocalizationModule } from '@abp/ng.core';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ExternalLoginComponent } from '@ralba-ng/core/account/feature-external-login';
import { LocalLoginComponent } from '@ralba-ng/core/account/feature-local-login';
import { RegisterComponent } from '@ralba-ng/core/account/feature-register';
import { CoreAccountUiBackToLoginComponent } from '@ralba-ng/core/account/ui-back-to-login';

@Component({
    standalone: true,
    selector: 'auth-shell-login-page',
    imports: [
        LocalizationModule,
        LocalLoginComponent,
        RouterLink,
        ExternalLoginComponent,
        RegisterComponent,
        CoreAccountUiBackToLoginComponent,
    ],
    template: `
        <h1 class="aui-font-bold-20 pb-2">
            {{ 'AppthorAccount::Register' | abpLocalization }}
        </h1>

        <div>
            <core-account-register />
        </div>
        <div>
            <core-account-ui-back-to-login />
        </div>
    `,
})
export class RegisterPageComponent {}
