import { AnyZodObject, z, ZodType } from 'zod';
import { SchemaService } from './schema.service';
import { O } from 'ts-toolbelt';

export type Primitives =
    | ZodType<string, never>
    | ZodType<boolean, never>
    | ZodType<number, never>
    | ZodType<Date, never>;
export type NullablePrimitives = z.ZodNullable<Primitives>;
export type OptionalPrimitives = z.ZodOptional<Primitives>;
export type NulishPrimitives = z.ZodOptional<NullablePrimitives>;

export const ExtensibleObject = z.object({
    extraProperties: z.record(z.any()).optional(),
});

export abstract class SchemaExtensionService<TSchema extends typeof ExtensibleObject> {
    extraPropertiesSchema = z.object({});

    protected constructor(public schema: TSchema) {}

    public extend(extension: {
        [key: string]: Primitives | NullablePrimitives | OptionalPrimitives | NulishPrimitives;
    }) {
        Object.assign(this.extraPropertiesSchema.shape, this.extraPropertiesSchema.extend(extension).shape);
    }

    public validate(model: z.infer<TSchema>) {
        if (!model.extraProperties) return this.extraPropertiesSchema.safeParse({});
        return this.extraPropertiesSchema.safeParse(model.extraProperties);
    }
}

export abstract class ExtensibleSchemaService<TSchema extends typeof ExtensibleObject> extends SchemaService<TSchema> {
    protected constructor(protected readonly extensionService: SchemaExtensionService<TSchema>) {
        super(extensionService.schema);
    }

    getProperty<T extends string | number | boolean | null | unknown = unknown>(
        obj: z.infer<TSchema> & { extraProperties: Record<string, never> },
        name: string
    ): T | undefined {
        if (!obj.extraProperties) obj.extraProperties = {};
        if (name in obj.extraProperties) {
            return obj.extraProperties[name] as T;
        }
        return undefined;
    }

    public override validate(model: z.infer<TSchema>): z.SafeParseReturnType<O.Object, z.infer<TSchema>> {
        const extraProperties = this.extensionService.validate(model);
        if (extraProperties.success)
            return super.validate({
                ...model,
                extraProperties: extraProperties.data,
            });
        return extraProperties;
    }

    setProperty<T extends string | number | boolean | null | undefined>(obj: z.infer<TSchema>, name: string, value: T) {
        if (!obj.extraProperties) obj.extraProperties = {};

        if ((this.extensionService.extraPropertiesSchema as AnyZodObject).shape[name]) {
            (this.extensionService.extraPropertiesSchema as AnyZodObject).shape[name].parse(value);
            Object.assign(obj.extraProperties, { [name]: value });
            return;
        }
        throw new Error(`${name} is not part of schema`);
    }
}
