import { APP_INITIALIZER, Provider } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { catchError, map, of } from 'rxjs';
import { DarkMode, LightMode } from '../themes-definitions';
import { AppLevelTheme, provideAppLevelTheme, SolveColorOverWrites, ThemeTokenConfiguration } from './aui-theme-token';
import { AuiThemeStoreToken } from './aui-theme.store';

export const provideAuiDarkTheme = (defaultPriorityIndex = 10, overwrites?: ThemeTokenConfiguration): Provider => {
    const theme = SolveColorOverWrites(DarkMode, overwrites);

    return provideAppLevelTheme({
        themeName: 'Dark',
        themeKey: 'DarkMode',
        defaultPriorityIndex,
        theme,
    });
};

export const provideAuiLightTheme = (defaultPriorityIndex = 10, overwrites?: ThemeTokenConfiguration): Provider => {
    const theme = SolveColorOverWrites(LightMode, overwrites);

    return provideAppLevelTheme({
        themeName: 'Light',
        themeKey: 'LightMode',
        defaultPriorityIndex,
        theme,
    });
};

/**
 *
 * @param url /assets/themes/custom-theme.json
 * @returns
 */
export const provideCustomThemes = (url: string): Provider => ({
    provide: APP_INITIALIZER,
    useFactory: (httpClient: HttpClient, store: { registerTheme: (p: AppLevelTheme) => void }) => {
        return () =>
            httpClient.get(url).pipe(
                map((themes) => {
                    (themes as AppLevelTheme[]).forEach((theme) => {
                        // TODO: ZOD validace vstupu
                        store.registerTheme(theme as AppLevelTheme);
                    });
                    return true;
                }),
                catchError(() => of(true))
            );
    },
    multi: true,
    deps: [HttpClient, AuiThemeStoreToken],
});
