import { inject, Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ComponentStore } from '@ngrx/component-store';
import { LoginResultType, LoginWithRecoveryCode, RecoveryCodeLoginFacade } from '@ralba-ng/core/account/domain';
import { map, startWith } from 'rxjs';

@Injectable()
export class RecoveryCodeLoginStore extends ComponentStore<never> {
    form = new FormGroup({
        recoveryCode: new FormControl('', {
            nonNullable: true,
            validators: [Validators.required, Validators.pattern(/^[A-Z0-9]{5}-[A-Z0-9]{5}$/)],
        }),
    });

    recoveryCodeLoginFacade = inject(RecoveryCodeLoginFacade);
    private readonly invalidRecoveryCodeResult$ = this.recoveryCodeLoginFacade.loginResult$.pipe(
        map(({ result }) => result === LoginResultType.InvalidUserNameOrPassword),
        startWith(false)
    );
    private readonly userLockedResult$ = this.recoveryCodeLoginFacade.loginResult$.pipe(
        map(({ result }) => result === LoginResultType.LockedOut),
        startWith(false)
    );
    public readonly vm$ = this.select({
        invalidRecoveryCodeResult: this.invalidRecoveryCodeResult$,
        userLockedResult: this.userLockedResult$,
    });

    public handleFormSubmit() {
        if (this.form.valid) {
            this.recoveryCodeLoginFacade.login(LoginWithRecoveryCode.parse(this.form.getRawValue()));
        }
    }
}
