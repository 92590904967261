import { LocalizationModule } from '@abp/ng.core';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AuiButtonComponent } from '@ral/ui:aui/button';
import { AuiFormFieldComponent, AuiFormFieldLabelComponent, AuiFormLayoutComponent } from '@ral/ui:aui/form-layout';
import { AuiInputComponent } from '@ral/ui:aui/input';
import { EMAIL_ADDRESS_PLACEHOLDER, PASSWORD_PLACEHOLDER, USERNAME_PLACEHOLDER } from '@ralba-ng/core/account/domain';
import { RegisterStore } from '../register.store';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        LocalizationModule,
        ReactiveFormsModule,
        AuiButtonComponent,
        AuiFormLayoutComponent,
        AuiFormFieldLabelComponent,
        AuiInputComponent,
        AuiFormFieldComponent,
    ],
    providers: [RegisterStore],
    selector: 'core-account-register-form',
    template: `
        <form [formGroup]="form" (ngSubmit)="handleFormSubmit()">
            <aui-form-layout>
                <aui-form-field>
                    <aui-form-field-label [label]="'AppthorAccount::UserName' | abpLocalization" />
                    <aui-input type="text" [formControl]="form.controls.userName" [placeholder]="userNamePlaceholder" />
                </aui-form-field>

                <aui-form-field>
                    <aui-form-field-label [label]="'AppthorAccount::Email' | abpLocalization" />
                    <aui-input
                        type="email"
                        [formControl]="form.controls.emailAddress"
                        [placeholder]="emailAddressPlaceholder"
                    />
                </aui-form-field>
                <aui-form-field>
                    <aui-form-field-label [label]="'AppthorAccount::Password' | abpLocalization" />
                    <aui-input
                        type="password"
                        [formControl]="form.controls.password"
                        [placeholder]="passwordPlaceholder"
                    />
                </aui-form-field>
                <aui-button
                    type="submit"
                    [label]="'AppthorAccount::Register' | abpLocalization"
                    [disabled]="form.invalid"
                    [extend]="true"
                    [size]="'40'"
                    [color]="'primary'"
                />
            </aui-form-layout>
        </form>
    `,
})
export class RegisterFromComponent {
    public readonly emailAddressPlaceholder = inject(EMAIL_ADDRESS_PLACEHOLDER);
    public readonly passwordPlaceholder = inject(PASSWORD_PLACEHOLDER);
    public readonly userNamePlaceholder = inject(USERNAME_PLACEHOLDER);
    private readonly state = inject(RegisterStore);
    public readonly form = this.state.form;

    public handleFormSubmit() {
        this.state.handleFormSubmit();
    }
}
