import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation, computed, input } from '@angular/core';
import { AuiIcon, AuiIconWeight } from '../models/icon';
import { FaIconName } from '../models/names';

@Component({
    selector: 'aui-icon',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [],
    template: `<i class="{{ calculatedWeight() }} fa-{{ icon() }}" [class.fa-fw]="fixedWidth"></i>`,
})
export class AuiIconComponent {
    icon = input.required<AuiIcon>();
    weight = input<AuiIconWeight | 'fa-kit' | null>('far');

    calculatedWeight = computed(() => {
        const weight = this.weight();
        const icon = this.icon();
        return FaIconName.includes(icon) ? weight : 'fa-kit';
    });

    /**
     * fixed width https://fontawesome.com/docs/web/style/fixed-width
     *  */
    @Input() fixedWidth = false;
}
