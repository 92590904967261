import { Configuration, TemporaryUploadService } from './openapi';
import { FileUploadResponse, provideUploadService } from '@ral/utils:core/files';
import { map, Observable } from 'rxjs';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { inject } from '@angular/core';
import { BusinessException } from '@ral/utils:core/exceptions';
import { catchOpenApiAbpException } from '@ral/utils:abp/http';
import { EnvironmentService } from '@abp/ng.core';

export const abpTemporaryUploadProvider = provideUploadService(() => {
    const proxy = new TemporaryUploadService(
        inject(HttpClient),
        inject(EnvironmentService).getApiUrl('blob'),
        new Configuration()
    );

    const uploadFile = (file: File): Observable<FileUploadResponse | { progress: number }> =>
        proxy.blobManagementTemporaryUploadUploadWithStream(file, 'events', true).pipe(
            map((event) => {
                if (event.type === HttpEventType.UploadProgress) {
                    return {
                        progress: event.total ? Math.round((100 * event.loaded) / event.total) : 0,
                    };
                }

                if (event.type === HttpEventType.Response) {
                    const body = event.body;
                    if (body) {
                        return {
                            file: body.file,
                            validUntil: body.validUntil,
                        } as FileUploadResponse;
                    }

                    throw new BusinessException('Empty Response', '500.102');
                }

                return {
                    progress: 0,
                };
            }),
            catchOpenApiAbpException()
        );

    return {
        uploadFile,
    };
});
