import darkMode from './dark-mode.json';
import lightMode from './light-mode.json';
import taxPrimaDarkMode from './tax-prima-dark-mode.json';
import taxPrimaLightMode from './tax-prima-mode.json';

export const LightMode = lightMode;
export const DarkMode = darkMode;
export const TaxPrimaDarkMode = taxPrimaDarkMode;
export const TaxPrimaLightMode = taxPrimaLightMode;

/*import michalMode from './michal-mode.json';
export const MichalMode = michalMode;*/
