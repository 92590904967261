import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '@abp/ng.core';
import { catchError, map, Observable } from 'rxjs';
import { RegisterModel } from '../entities/register';

import { throwErrorResponse } from '@ralba-ng/shared/util-core';

@Injectable({ providedIn: 'root' })
export class RegisterHttpService {
    protected readonly http = inject(HttpClient);
    protected readonly environment = inject(EnvironmentService);

    public register(input: RegisterModel): Observable<boolean> {
        return this.http.post('/auth/register', input).pipe(
            map(() => true),
            catchError(({ error }) => {
                throwErrorResponse(error.error);
            })
        );
    }
}
