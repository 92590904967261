import { provideAuthService } from '@ral/utils:core/auth';
import { computed, inject } from '@angular/core';
import { AuthService, ConfigStateService } from '@abp/ng.core';
import { toSignal } from '@angular/core/rxjs-interop';

export const abpAuthService = provideAuthService(() => {
    const configState = inject(ConfigStateService);
    const auth = inject(AuthService);

    const currentUser = toSignal(configState.getOne$('currentUser'), { initialValue: null });
    const isAuthenticated = computed(() => {
        const user = currentUser();
        if (user) return user.isAuthenticated;
        return false;
    });

    return {
        currentUser,
        isAuthenticated,
        logout: () => auth.logout(),
    };
});
