export type RowStyle = {
    field: string;
    label: string;
    error: string;
};

export const RowStylesKeysOptions = ['alwaysCol', 'md180', 'alwaysRow'] as const;

export type RowStylesKeys = (typeof RowStylesKeysOptions)[number];

export const AUI_FIELD_ROW_STYLES: Record<RowStylesKeys, RowStyle> = {
    alwaysCol: {
        field: '@[2500px]:flex-row @[2500px]:items-start',
        label: '@[2500px]:w-[180px] @[2500px]:min-h-8',
        // plus gap-2
        error: '@[2500px]:pl-[188px]',
    },
    md180: {
        field: '@lg:flex-row @lg:items-start',
        label: '@lg:w-[180px] @lg:min-h-8',
        // plus gap-2
        error: '@lg:pl-[188px]',
    },
    alwaysRow: {
        field: '@[0px]:flex-row @[0px]:items-start',
        label: '@[0px]:w-[180px] @[0px]:min-h-8',
        // plus gap-2
        error: '@[0px]:pl-[188px]',
    },
};
