import { IToastData } from '../models/toast.model';
import { IToastService } from '../interfaces/toast.interface';
import { createInjectionToken } from 'ngxtension/create-injection-token';

export const [injectToastService, provideToastService] = createInjectionToken<() => IToastService>(() => {
    function error(data: IToastData): void {
        console.error(data.title, data.message);
    }

    function info(data: IToastData): void {
        console.info(data.title, data.message);
    }

    function success(data: IToastData): void {
        console.log(data.title, data.message);
    }

    function warning(data: IToastData): void {
        console.warn(data.title, data.message);
    }

    return {
        error,
        info,
        success,
        warning,
    };
});
