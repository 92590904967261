/**
 * Blob management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient,
    HttpContext,
    HttpEvent,
    HttpHeaders,
    HttpParameterCodec,
    HttpParams,
    HttpResponse,
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

// @ts-ignore
import { AppthorBlobStoringBlobDownloadInfoDto } from '../model/appthorBlobStoringBlobDownloadInfoDto';
// @ts-ignore
import { RalbaBlobManagementFileUploadResponse } from '../model/ralbaBlobManagementFileUploadResponse';
// @ts-ignore
import { VoloAbpHttpRemoteServiceErrorResponse } from '../model/voloAbpHttpRemoteServiceErrorResponse';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
    providedIn: 'root',
})
export class TemporaryUploadService {
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;
    protected basePath = 'http://localhost';

    constructor(
        protected httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string | string[],
        @Optional() configuration: Configuration
    ) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === 'object' && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === 'object') {
            if (Array.isArray(value)) {
                (value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                    throw Error('key may not be null if value is Date');
                }
            } else {
                Object.keys(value).forEach(
                    (k) =>
                        (httpParams = this.addToHttpParamsRecursive(
                            httpParams,
                            value[k],
                            key != null ? `${key}.${k}` : k
                        ))
                );
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error('key may not be null if value is not object or array');
        }
        return httpParams;
    }

    /**
     * @param blobName
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public blobManagementTemporaryUploadGetDownloadInfo(
        blobName: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json';
            context?: HttpContext;
            transferCache?: boolean;
        }
    ): Observable<AppthorBlobStoringBlobDownloadInfoDto>;
    public blobManagementTemporaryUploadGetDownloadInfo(
        blobName: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json';
            context?: HttpContext;
            transferCache?: boolean;
        }
    ): Observable<HttpResponse<AppthorBlobStoringBlobDownloadInfoDto>>;
    public blobManagementTemporaryUploadGetDownloadInfo(
        blobName: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json';
            context?: HttpContext;
            transferCache?: boolean;
        }
    ): Observable<HttpEvent<AppthorBlobStoringBlobDownloadInfoDto>>;
    public blobManagementTemporaryUploadGetDownloadInfo(
        blobName: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json';
            context?: HttpContext;
            transferCache?: boolean;
        }
    ): Observable<any> {
        if (blobName === null || blobName === undefined) {
            throw new Error(
                'Required parameter blobName was null or undefined when calling blobManagementTemporaryUploadGetDownloadInfo.'
            );
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/blob/temp/${this.configuration.encodeParam({ name: 'blobName', value: blobName, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}/download-info`;
        return this.httpClient.request<AppthorBlobStoringBlobDownloadInfoDto>(
            'get',
            `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param content
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public blobManagementTemporaryUploadUploadWithStream(
        content?: Blob,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json';
            context?: HttpContext;
            transferCache?: boolean;
        }
    ): Observable<RalbaBlobManagementFileUploadResponse>;
    public blobManagementTemporaryUploadUploadWithStream(
        content?: Blob,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json';
            context?: HttpContext;
            transferCache?: boolean;
        }
    ): Observable<HttpResponse<RalbaBlobManagementFileUploadResponse>>;
    public blobManagementTemporaryUploadUploadWithStream(
        content?: Blob,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json';
            context?: HttpContext;
            transferCache?: boolean;
        }
    ): Observable<HttpEvent<RalbaBlobManagementFileUploadResponse>>;
    public blobManagementTemporaryUploadUploadWithStream(
        content?: Blob,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {
            httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json';
            context?: HttpContext;
            transferCache?: boolean;
        }
    ): Observable<any> {
        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }

        // to determine the Content-Type header
        const consumes: string[] = ['multipart/form-data'];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({ encoder: this.encoder });
        }

        if (content !== undefined) {
            localVarFormParams = (localVarFormParams.append('Content', <any>content) as any) || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/blob/temp/upload-with-stream`;
        return this.httpClient.request<RalbaBlobManagementFileUploadResponse>(
            'post',
            `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress,
            }
        );
    }
}
