import { Component, inject } from '@angular/core';
import { ResetPasswordFacade } from '@ralba-ng/core/account/domain';
import { LocalizationModule } from '@abp/ng.core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { tap } from 'rxjs';

@Component({
    standalone: true,
    selector: 'core-account-forgot-password',
    imports: [LocalizationModule, RouterOutlet],
    template: ` <router-outlet></router-outlet> `,
})
export class CoreAccountForgotPasswordComponent extends ComponentStore<never> {
    facade = inject(ResetPasswordFacade);
    router = inject(Router);
    activatedRoute = inject(ActivatedRoute);

    onSuccess = this.effect<void>(() =>
        this.facade.passwordResetLinkSent$.pipe(
            tap(() => {
                this.router.navigate(['link-sent'], { relativeTo: this.activatedRoute });
            })
        )
    );
}
