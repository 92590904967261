import { CoreModule, Environment } from '@abp/ng.core';
import { AbpOAuthModule } from '@abp/ng.oauth';
import { EnvironmentProviders, importProvidersFrom, Provider } from '@angular/core';
import { DefaultTitleStrategy, TitleStrategy } from '@angular/router';
import { abpTemporaryUploadProvider } from '@ral/utils:abp/generated';
import { provideValidationMessages } from '@ral/utils:core/validation';
import { abpAuthService } from './auth.provider';
import { injectLocalization } from './injectPermissionChecker';

// See https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#limitations for supported dynamic import formats. If this is intended to be left as-is, you can use the /* @vite-ignore */ comment inside the import() call to suppress this warning.
export function registerLocale() {
    return import(`@angular/common/locales/cs`);
}

export const provideAbpCore = (environment: Partial<Environment>, auth = true): (Provider | EnvironmentProviders)[] => [
    importProvidersFrom(
        CoreModule.forRoot({
            environment,
            registerLocaleFn: registerLocale,
        }),
        auth ? AbpOAuthModule.forRoot() : []
    ),
    abpAuthService,
    abpTemporaryUploadProvider,
    provideValidationMessages(() => {
        const localization = injectLocalization();
        return {
            min: (args) => localization.instant('AbpValidation::ThisFieldMustBeGreaterThanOrEqual{0}', `${args.min}`),
            max: (args) => localization.instant('AbpValidation::ThisFieldMustBeLessOrEqual{0}', `${args.max}`),
            required: () => localization.instant('AbpValidation::ThisFieldIsRequired.'),
            requiredTrue: () => localization.instant('AbpValidation::ThisFieldIsRequired.'),
            email: () => localization.instant('AbpValidation::ThisFieldIsNotAValidEmailAddress.'),
            minlength: (args) =>
                localization.instant(
                    'AbpValidation::ThisFieldMustBeAStringOrArrayTypeWithAMinimumLengthOf{0}',
                    `${args.requiredLength}`
                ),
            maxlength: (args) =>
                localization.instant(
                    'AbpValidation::ThisFieldMustBeAStringOrArrayTypeWithAMaximumLengthOf{0}',
                    `${args.requiredLength}`
                ),
            pattern: (args) =>
                localization.instant(
                    'AbpValidation::ThisFieldMustMatchTheRegularExpression{0}',
                    `${args.requiredPattern}`
                ),
            invalidBirthNumber: () => localization.instant('AbpValidation::InvalidBirthNumber.'),
            dateIsInFuture: () => localization.instant('AbpValidation::DateIsInFuture.'),
            containsDigit: () => localization.instant('AbpValidation::ContainsDigit.'),
            containsLowercase: () => localization.instant('AbpValidation::ContainsLowercase.'),
            containsUppercase: () => localization.instant('AbpValidation::ContainsUppercase.'),
            containsNonAlphanumeric: () => localization.instant('AbpValidation::ContainsNonAlphanumeric.'),
            containsUniqueChars: () => localization.instant('AbpValidation::ContainsUniqueChars.'),
        };
    }),
    { provide: TitleStrategy, useClass: DefaultTitleStrategy },
];
