import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalizationModule } from '@abp/ng.core';
import { RecoveryCodeLoginStore } from './recovery-code-login.store';
import { RecoveryCodeLoginFormComponent } from './components/recovery-code-login-form.component';

@Component({
    standalone: true,
    imports: [CommonModule, LocalizationModule, RecoveryCodeLoginFormComponent],
    providers: [RecoveryCodeLoginStore],
    selector: 'core-account-recovery-code-login',
    template: ` <ng-container *ngIf="state.vm$ | async as vm">
        <small *ngIf="vm.invalidRecoveryCodeResult" class="p-error">{{
            'AppthorAccount::InvalidRecoveryCode' | abpLocalization
        }}</small>

        <core-account-recovery-code-login-form />
    </ng-container>`,
})
export class RecoveryCodeLoginComponent {
    public readonly state = inject(RecoveryCodeLoginStore);
}
