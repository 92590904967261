import { IConfirmation, provideConfirmation } from '@ral/utils:core/confirmation';
import { ConfirmationComponent } from './confirmation.component';
import { inject, Injector } from '@angular/core';
import { Dialog } from '@angular/cdk/dialog';
import { provideConfirmationData } from './confirmation-data.provider';

export const provideAuiConfirmation = provideConfirmation(() => {
    const dialog = inject(Dialog);
    const injector = inject(Injector);

    function confirm(args: IConfirmation) {
        const dialogId = 'confirmation';
        if (dialog.getDialogById(dialogId)) return;

        const dialogInjector = Injector.create({
            parent: injector,
            providers: [provideConfirmationData(args)],
        });

        dialog.open(ConfirmationComponent, {
            id: dialogId,
            closeOnNavigation: true,
            injector: dialogInjector,
        });
    }

    return {
        confirm,
    };
});
