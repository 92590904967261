import { ZodError } from 'zod';

export abstract class Exception extends Error {
    protected constructor(
        message: string,
        public readonly code = '500',
        public readonly details: string | null = null,
        public readonly data: {
            [key: string]: unknown;
        } | null = null
    ) {
        super(message);
    }
}

export class NotFoundException extends Exception {
    constructor(message: string | null, details: string | null = null, data: { [p: string]: unknown } | null = null) {
        super(message || 'NotFoundException', '404', details, data);
    }
}

export class BusinessException extends Exception {
    constructor(
        message: string | null,
        code: string | null,
        details: string | null = null,
        data: {
            [p: string]: unknown;
        } | null = null
    ) {
        super(message || 'BusinessException', code || '403', details, data);
    }
}

export class ZodValidationException extends Exception {
    constructor(zodError: ZodError) {
        super(zodError.message, '500.100', zodError.stack, {
            issues: zodError.errors,
        });
    }
}
