import { inject, Injector, INJECTOR } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { createInjectionToken, createNoopInjectionToken } from 'ngxtension/create-injection-token';

export type ValidationMessageFn<T extends ValidationErrors | null = null> = (args: T) => string;

export type ValidationMessages = {
    min: ValidationMessageFn<{ min: number; actual: number }>;
    max: ValidationMessageFn<{ max: number; actual: number }>;
    required: ValidationMessageFn;
    requiredTrue: ValidationMessageFn;
    email: ValidationMessageFn;
    minlength: ValidationMessageFn<{ requiredLength: number; actualLength: number }>;
    maxlength: ValidationMessageFn<{ requiredLength: number; actualLength: number }>;
    pattern: ValidationMessageFn<{ requiredPattern: string; actualValue: string }>;
    nullValidator: ValidationMessageFn;
    [key: string]: ValidationMessageFn<never>;
};

export const [injectValidationMessages, provideValidationMessages] = createNoopInjectionToken<
    () => Partial<ValidationMessages>,
    false
>('Validation Messages');

export const [injectValidationDictionary, provideValidationDictionary] = createInjectionToken<
    () => Partial<ValidationMessages>
>(() => {
    const injectRecursively = (
        injector: Injector | undefined | null,
        _prev: Partial<ValidationMessages>
    ): Partial<ValidationMessages> => {
        if (injector === undefined || injector === null) return _prev;
        const current = injectValidationMessages({ optional: true, injector });
        if (current === null || current === undefined) return _prev;
        return injectRecursively(injector.get(INJECTOR, undefined, { skipSelf: true, optional: true }), {
            ...current,
            ..._prev,
        });
    };

    return {
        min: (args) => `Min: ${args?.min}`,
        max: (args) => `Max: ${args?.max}`,
        required: () => `Required `,
        requiredTrue: () => `RequiredT rue `,
        email: () => `Email `,
        minlength: (args) => `Min length ${args?.requiredLength}`,
        maxlength: (args) => `Max length ${args?.requiredLength}`,
        pattern: (args) => `Pattern ${args?.requiredPattern}`,
        nullValidator: () => `Null Validator`,
        invalidBirthNumber: () => `Invalid birth number`,
        dateIsInFuture: () => `Date is in future`,
        containsDigit: () => `Must contain digit`,
        containsLowercase: () => `Must contain lowercase character`,
        containsUppercase: () => `Must contain lowercase character`,
        containsNonAlphanumeric: () => `Must contain special character`,
        containsUniqueChars: () => `Must contain unique characters`,

        ...injectRecursively(inject(INJECTOR), {} as ValidationMessages),
    };
});
