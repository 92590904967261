import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { ResetPasswordFacade } from '@ralba-ng/core/account/domain';
import { distinctUntilChanged, map, tap } from 'rxjs';

@Component({
    standalone: true,
    selector: 'core-account-reset-password',
    imports: [CommonModule, RouterOutlet],
    template: ` <router-outlet></router-outlet> `,
})
export class CoreAuthResetPasswordComponent extends ComponentStore<never> {
    facade = inject(ResetPasswordFacade);
    router = inject(Router);
    activatedRoute = inject(ActivatedRoute);

    onPasswordReset$ = this.effect<void>(() =>
        this.facade.passwordResetSuccessFully$.pipe(
            tap(() => {
                this.router.navigate(['success'], { relativeTo: this.activatedRoute });
            })
        )
    );

    onPasswordVerificationFailed$ = this.effect<void>(() =>
        this.facade.passwordResetTokenVerificationFailed$.pipe(
            tap(() => {
                this.router.navigate(['/', 'login']);
            })
        )
    );

    verifyToken = this.effect<void>(() =>
        this.activatedRoute.queryParams.pipe(
            map((params) => {
                return {
                    userId: params['UserId'],
                    resetToken: params['resetToken'],
                };
            }),
            distinctUntilChanged(),
            tap((params) => {
                this.facade.verifyPasswordResetToken({
                    resetToken: params.resetToken,
                    userId: params.userId,
                });
            })
        )
    );
}
