import { DialogRef } from '@angular/cdk/dialog';
import { Component, inject } from '@angular/core';
import { AuiButtonComponent } from '@ral/ui:aui/button';
import { injectAuiTranslationDictionary } from '@ral/utils:core/ui';
import { injectConfirmationData } from './confirmation-data.provider';

@Component({
    standalone: true,
    selector: 'aui-confirmation',
    imports: [AuiButtonComponent],
    template: `
        <div class="flex flex-col w-full h-full max-w-[50vw] max-h-[90vh] bg-surface-background rounded-md m-auto">
            <div
                class="p-4 border-solid border-gray-400 border-b border-surface-background-darken flex flex-row flex-none justify-between items-center"
            >
                <span class="aui-font-medium-16">
                    {{ data.header ?? header }}
                </span>
            </div>

            <div class="p-4 flex flex-col gap-4 h-full overflow-y-auto">
                <p>{{ data.message ?? message }}</p>
            </div>

            <div class="p-4 flex flex-col gap-4 border-t  border-solid  border-surface-background-darken flex-none">
                <div class="w-full">
                    <div class="flex flex-row justify-between gap-2">
                        <aui-button
                            [label]="data.rejectLabel ?? rejectLabel"
                            (clicked)="handleReject()"
                            [color]="data.rejectStyle ?? 'gray'"
                            buttonStyle="outline"
                            size="32"
                        />
                        <aui-button
                            data-testid="aui-confirmation-accept-button"
                            [label]="data.acceptLabel ?? acceptLabel"
                            (clicked)="handleAccept()"
                            [color]="data.acceptStyle ?? 'primary'"
                            buttonStyle="solid"
                            size="32"
                        />
                    </div>
                </div>
            </div>
        </div>
    `,
})
export class ConfirmationComponent {
    public readonly data = injectConfirmationData();

    translations = injectAuiTranslationDictionary();
    header = this.translations.confirmationHeader();
    message = this.translations.confirmationMessage();
    rejectLabel = this.translations.reject();
    acceptLabel = this.translations.accept();

    public readonly dialogRef = inject(DialogRef);

    public handleAccept() {
        if (this.data.accept) this.data.accept();
        this.dialogRef.close();
    }

    public handleReject() {
        if (this.data.reject) this.data.reject();
        this.dialogRef.close();
    }
}
