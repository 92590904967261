import { inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { exhaustMap, Observable, tap } from 'rxjs';
import {
    ConfigStateService,
    EnvironmentService,
    MultiTenancyService,
    SessionStateService,
    TENANT_KEY,
} from '@abp/ng.core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class SwitchTenantFacade extends ComponentStore<never> {
    protected readonly environment = inject(EnvironmentService);

    protected readonly multiTenancy = inject(MultiTenancyService);
    protected readonly sessionState = inject(SessionStateService);
    currentTenant$ = this.sessionState.getTenant$();
    protected readonly configState = inject(ConfigStateService);
    protected readonly cookie = inject(CookieService);
    protected readonly tenantKey = inject(TENANT_KEY);
    private setHost$ = this.effect(($: Observable<void>) =>
        $.pipe(
            tap(() => this.sessionState.setTenant(null)),
            tap(() => this.cookie.delete(this.tenantKey, '/')),
            exhaustMap(() => this.configState.refreshAppState())
        )
    );

    private setTenant$ = this.effect(($: Observable<string>) =>
        $.pipe(
            exhaustMap((name) => this.multiTenancy.setTenantByName(name)),
            tap((result) => {
                if (!result.success) {
                    // TODO: Tenant not found
                }
            })
        )
    );
    private handleSwitchTenant$ = this.effect(($: Observable<string | null>) =>
        $.pipe(
            tap((name) => {
                name === null ? this.setHost$() : this.setTenant$(name);
            })
        )
    );

    public switchTenant(name: string | null) {
        this.handleSwitchTenant$(name);
    }
}
