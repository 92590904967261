import { AbpOAuthModule } from '@abp/ng.oauth';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter, withEnabledBlockingInitialNavigation } from '@angular/router';
import { provideCustomThemes } from '@ral/ui:aui/theme';
import { provideAuiBasicTheme } from '@ral/ui:aui:theme:basic/config';
import { provideAbpCore } from '@ral/utils:abp';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom([
            BrowserModule,
            BrowserAnimationsModule,
            AbpOAuthModule.forRoot(),
            HttpClientModule,
            HttpClientXsrfModule.withOptions({
                cookieName: 'XSRF-TOKEN',
                headerName: 'RequestVerificationToken',
            }),
        ]),
        provideAbpCore(environment, false),
        provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),
        provideAuiBasicTheme(),
        provideCustomThemes('/assets/themes/identity-themes.json'),
    ],
};
