import { createNoopInjectionToken } from 'ngxtension/create-injection-token';
import { Observable } from 'rxjs';
import { FileUploadResponse } from './file-upload.response';

export interface IUploadService {
    uploadFile(file: File): Observable<FileUploadResponse | { progress: number }>;
}

export const [injectUploadService, provideUploadService] = createNoopInjectionToken<IUploadService, false>(
    'Temporary file upload service'
);
