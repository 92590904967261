import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '@abp/ng.core';
import { ExternalProvider } from '../entities/external-provider';
import { Observable, switchMap } from 'rxjs';
import { z } from 'zod';

@Injectable({ providedIn: 'root' })
export class ExternalLoginService {
    protected readonly http = inject(HttpClient);
    protected readonly environment = inject(EnvironmentService);

    public getExternalProviders(): Observable<Array<ExternalProvider>> {
        return this.http
            .get('/auth/externalProviders')
            .pipe(switchMap((response) => z.array(ExternalProvider).parseAsync(response)));
    }

    public externalLogin(input: ExternalProvider, returnUrl: string) {
        const url = `/auth/login/external?provider=${
            input.authenticationScheme
        }&returnUrl=${encodeURIComponent(returnUrl)}`;
        location.replace(url);
    }
}
