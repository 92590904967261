import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AuiColor } from '@ral/ui:aui/utils';
import { injectAuiTranslationDictionary } from '@ral/utils:core/ui';
import { NgxControlValueAccessor } from 'ngxtension/control-value-accessor';
import { hostBinding } from 'ngxtension/host-binding';
import { InputTextModule } from 'primeng/inputtext';

@Component({
    selector: 'aui-input',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [CommonModule, InputTextModule, ReactiveFormsModule],
    hostDirectives: [
        {
            directive: NgxControlValueAccessor,
            inputs: ['value'],
            outputs: ['valueChange'],
        },
    ],
    styleUrl: 'aui-input.component.scss',
    template: `
        <input
            data-testid="input"
            [value]="value()"
            [type]="type()"
            (input)="input($event)"
            [disabled]="cva.disabled$()"
            [readonly]="readonly()"
            (blur)="cva.markAsTouched()"
            [placeholder]="determinePlaceholder()"
            class="h-full px-2 py-1 focus:outline-none focus:ring bg-transparent min-w-full max-w-full text-sm"
        />
    `,
    styles: [
        `
            :host {
                @apply block;
            }

            :host #file-upload-button {
                display: none;
            }
        `,
    ],
})
export class AuiInputComponent {
    translations = injectAuiTranslationDictionary();
    showClear = input<boolean>(false);
    placeholder = input<string | null>(this.translations.inputPlaceholder());
    readonly = input<boolean>(false);
    type = input<'text' | 'number' | 'email' | 'password' | 'file'>('text');
    color = input<AuiColor>('gray');
    rounded = input(false);
    alwaysShowPlaceholder = input<boolean>(false);

    protected cva = inject<NgxControlValueAccessor<string | null | undefined>>(NgxControlValueAccessor);
    value = computed(() => {
        return this.cva.value$() ?? null;
    });

    determinePlaceholder = computed(() => {
        const isReadonly = this.readonly();
        const value = this.value();
        const alwaysShow = this.alwaysShowPlaceholder();
        return isReadonly && (!value || value.length === 0) && !alwaysShow ? '' : this.placeholder() || '';
    });

    calculatedHostClasses = hostBinding(
        'attr.class',
        computed(() => {
            const tailwindClasses = [
                'block',
                'h-8',
                'aui-font-regular-16',
                'transition-all',
                'overflow-hidden',
                'border',
                'border-input',
                'w-full',
            ];
            const color = this.color();
            const rounded = this.rounded();

            const focusRing: Record<AuiColor, string[]> = {
                transparent: [],
                gray: ['ring-gray'],
                primary: ['ring-primary'],
                success: ['ring-success'],
                danger: ['ring-danger'],
                warning: ['ring-warning'],
                info: ['ring-info'],
                help: ['ring-help'],
            };

            if (this.cva.disabled$()) {
                tailwindClasses.push('bg-surface-background-dark', 'border-surface-background');
            } else {
                tailwindClasses.push('border-surface-background-darker', 'hover:border-surface-background-darken');
            }

            if (this.type() === 'file') {
                tailwindClasses.push('bg-transparent', 'text-sm');
            }

            tailwindClasses.push(...focusRing[color]);

            tailwindClasses.push(rounded ? 'rounded-full' : 'rounded');

            return tailwindClasses.join(' ');
        })
    );

    input(event: Event) {
        if (event.target) this.cva.value = (event.target as HTMLInputElement).value;
    }
}
