export const hexToHSL = (hex: string) => {
    // TODO entry validation
    const splited = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (splited) {
        const result = {
            r: parseInt(splited[1], 16),
            g: parseInt(splited[2], 16),
            b: parseInt(splited[3], 16),
        };
        return rgbToHSL(result.r, result.g, result.b);
    }
    return null;
};

export const rgbToHSL = (r: number, g: number, b: number) => {
    r /= 255;
    g /= 255;
    b /= 255;
    const l = Math.max(r, g, b);
    const s = l - Math.min(r, g, b);
    const h = s ? (l === r ? (g - b) / s : l === g ? 2 + (b - r) / s : 4 + (r - g) / s) : 0;
    return [
        60 * h < 0 ? 60 * h + 360 : 60 * h,
        100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0),
        (100 * (2 * l - s)) / 2,
    ];
};

export const hslToHex = (hsl: string) => {
    const [h, s, tempL] = hsl
        .replace('deg', '')
        .replace('%', '')
        .replace('%', '')
        .replace('  ', ' ')
        .replace('  ', ' ')
        .replace('  ', ' ')
        .replace('  ', ' ')
        .replace('  ', ' ')
        .split(' ')
        .map((p) => parseFloat(p));

    const l = tempL / 100;
    const a = (s * Math.min(l, 1 - l)) / 100;
    const f = (n: number) => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color)
            .toString(16)
            .padStart(2, '0'); // convert to Hex and prefix "0" if needed
    };
    if (Number.isNaN(f(0))) {
        console.log(hsl, a, f(4));
    }
    return `#${f(0)}${f(8)}${f(4)}`;
};
