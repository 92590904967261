import { Component, computed, ElementRef, EventEmitter, inject, input, model, Output } from '@angular/core';
import { AuiIcon, AuiIconComponent, AuiIconWeight } from '@ral/ui:aui/icons';
import { AuiColor } from '@ral/ui:aui/utils';

import { AuiButtonSizeType, AuiButtonStyleType } from '../models/button';

@Component({
    selector: 'aui-button',
    standalone: true,
    //changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [AuiIconComponent],
    template: `
        <button
            [type]="type()"
            [disabled]="disabled()"
            (click)="clicked.emit()"
            (focus)="focused.emit()"
            (blur)="blured.emit()"
            [class]="calculatedHostClasses()"
        >
            @if (icon(); as icon) {
                <div class="flex-none">
                    <aui-icon [icon]="icon" [fixedWidth]="true" [weight]="iconWeight()" />
                </div>
            }
            @if (label(); as label) {
                <div class="flex-shrink min-w-0 px-1 aui-font-regular-16">{{ label }}</div>
            }
            @if (rightIcon(); as rightIcon) {
                <div class="flex-none">
                    <aui-icon [icon]="rightIcon" [fixedWidth]="true" [weight]="iconWeight()" />
                </div>
            }
        </button>
    `,
    styles: [
        `
            :host {
                @apply max-w-fit;
            }
        `,
    ],
})
export class AuiButtonComponent {
    @Output() clicked = new EventEmitter<Event>();
    @Output() focused = new EventEmitter<Event>();
    // TODO: Better event name???
    @Output() blured = new EventEmitter<FocusEvent>();

    elementRef = inject(ElementRef);

    iconWeight = input<AuiIconWeight>('far');
    type = input<'button' | 'submit'>('button');
    icon = input<AuiIcon | null>(null);
    rightIcon = input<AuiIcon | null>(null);
    size = model<AuiButtonSizeType>('28');
    color = model<AuiColor>('gray');
    label = input<string | null | undefined>(null);
    buttonStyle = model<AuiButtonStyleType>('solid');
    rounded = model<boolean>(false);
    disabled = model<boolean>(false);
    // make button foll width
    extend = input<boolean>(false);

    calculatedHostClasses = computed(() => {
        const extend = this.extend();
        const size = this.size();
        const color = this.color();
        const buttonStyle = this.buttonStyle();
        const label = this.label();
        const disabled = this.disabled();
        const rounded = this.rounded();

        let tailwindClasses = [
            'flex',
            'flex-nowrap',
            'justify-center',
            'gap-2',
            'aui-font-regular-16',
            'border-solid',
            'border',
            'text-center',
            'transition-all',
            'forced-color-adjust-none',
        ];

        const borders: Record<AuiColor, string[]> = {
            transparent: ['border-transparent', 'hover:border-transparent', 'active:border-transparent'],
            gray: ['border-gray', 'hover:border-gray-dark', 'active:border-gray-darken'],
            primary: ['border-primary', 'hover:border-primary-dark', 'active:border-primary-darken'],
            success: ['border-success', 'hover:border-success-dark', 'active:border-success-darken'],
            danger: ['border-danger', 'hover:border-danger-dark', 'active:border-danger-darken'],
            warning: ['border-warning', 'hover:border-warning-dark', 'active:border-warning-darken'],
            info: ['border-info', 'hover:border-info-dark', 'active:border-info-darken'],
            help: ['border-help', 'hover:border-help-dark', 'active:border-help-darken'],
        };

        const solidBackgrounds: Record<AuiColor, string[]> = {
            transparent: ['bg-transparent', 'hover:bg-transparent', 'active:bg-transparent'],
            gray: ['bg-gray', 'hover:bg-gray-dark', 'active:bg-gray-darken'],
            primary: ['bg-primary', 'hover:bg-primary-dark', 'active:bg-primary-darken'],
            success: ['bg-success', 'hover:bg-success-dark', 'active:bg-success-darken'],
            danger: ['bg-danger', 'hover:bg-danger-dark', 'active:bg-danger-darken'],
            warning: ['bg-warning', 'hover:bg-warning-dark', 'active:bg-warning-darken'],
            info: ['bg-info', 'hover:bg-info-dark', 'active:bg-info-darken'],
            help: ['bg-help', 'hover:bg-help-dark', 'active:bg-help-darken'],
        };

        const transparentBackgrounds: Record<AuiColor, string[]> = {
            transparent: [],
            gray: ['bg-gray/[0]', 'hover:bg-gray-dark/[.1]', 'active:bg-gray-darken/[.2]'],
            primary: ['bg-primary/[0]', 'hover:bg-primary-dark/[.1]', 'active:bg-primary-darken/[.2]'],
            success: ['bg-success/[0]', 'hover:bg-success-dark/[.1]', 'active:bg-success-darken/[.2]'],
            danger: ['bg-danger/[0]', 'hover:bg-danger-dark/[.1]', 'active:bg-danger-darken/[.2]'],
            warning: ['bg-warning/[0]', 'hover:bg-warning-dark/[.1]', 'active:bg-warning-darken/[.2]'],
            info: ['bg-info/[0]', 'hover:bg-info-dark/[.1]', 'active:bg-info-darken/[.2]'],
            help: ['bg-help/[0]', 'hover:bg-help-dark/[.1]', 'active:bg-help-darken/[.2]'],
        };

        const decentBackgrounds: Record<AuiColor, string[]> = {
            transparent: [],
            gray: ['bg-gray/[.1]', 'hover:bg-gray-dark/[.2]', 'active:bg-gray-darken/[.3]'],
            primary: ['bg-primary/[.1]', 'hover:bg-primary-dark/[.2]', 'active:bg-primary-darken/[.3]'],
            success: ['bg-success/[.1]', 'hover:bg-success-dark/[.2]', 'active:bg-success-darken/[.3]'],
            danger: ['bg-danger/[.1]', 'hover:bg-danger-dark/[.2]', 'active:bg-danger-darken/[.3]'],
            warning: ['bg-warning/[.1]', 'hover:bg-warning-dark/[.2]', 'active:bg-warning-darken/[.3]'],
            info: ['bg-info/[.1]', 'hover:bg-info-dark/[.2]', 'active:bg-info-darken/[.3]'],
            help: ['bg-help/[.1]', 'hover:bg-help-dark/[.2]', 'active:bg-help-darken/[.3]'],
        };

        const reverseText: Record<AuiColor, string[]> = {
            transparent: [],
            gray: ['text-gray-text'],
            primary: ['text-primary-text'],
            success: ['text-success-text'],
            danger: ['text-danger-text'],
            warning: ['text-warning-text'],
            info: ['text-info-text'],
            help: ['text-help-text'],
        };

        const coloredText: Record<AuiColor, string[]> = {
            transparent: ['text-inherit', 'hover:text-inherit', 'active:text-inherit'],
            gray: ['text-gray', 'hover:text-gray', 'active:text-gray'],
            primary: ['text-primary', 'hover:text-primary-dark', 'active:text-primary-darken'],
            success: ['text-success', 'hover:text-success-dark', 'active:text-success-darken'],
            danger: ['text-danger', 'hover:text-danger-dark', 'active:text-danger-darken'],
            warning: ['text-warning', 'hover:text-warning-dark', 'active:text-warning-darken'],
            info: ['text-info', 'hover:text-info-dark', 'active:text-info-darken'],
            help: ['text-help', 'hover:text-help-dark', 'active:text-help-darken'],
        };

        const focusRing: Record<AuiColor, string[]> = {
            transparent: [],
            gray: ['ring-gray'],
            primary: ['ring-primary'],
            success: ['ring-success'],
            danger: ['ring-danger'],
            warning: ['ring-warning'],
            info: ['ring-info'],
            help: ['ring-help'],
        };

        const squareWidth: Record<AuiButtonSizeType, string> = {
            '24': 'w-6',
            '28': 'w-7',
            '32': 'w-8',
            '40': 'w-10',
            '48': 'w-12',
        };

        tailwindClasses.push(extend ? 'block' : 'inline-block');

        // compute y paddings
        if (size === '48') {
            tailwindClasses.push('py-[12px]');
        }
        if (size === '40') {
            tailwindClasses.push('py-[8px]');
        }
        if (size === '32') {
            tailwindClasses.push('py-[5px]');
        }
        if (size === '24') {
            tailwindClasses.push('py-[1px]');
        }
        if (size === '28') {
            tailwindClasses.push('py-[3px]');
        }

        // icon only or with label
        if (!label) {
            tailwindClasses.push(squareWidth[size]);
        } else {
            if (size === '48') {
                tailwindClasses.push('px-6 aui-font-regular-20');
            } else if (size === '40') {
                tailwindClasses.push('px-4 aui-font-regular-18');
            } else {
                tailwindClasses.push('px-2');
            }
        }

        // rounded
        // TODO: groups
        tailwindClasses.push(rounded ? 'rounded-full' : 'rounded');

        // colors

        if (buttonStyle === 'solid') {
            tailwindClasses.push(...borders[color]);
            tailwindClasses.push(...solidBackgrounds[color]);
            tailwindClasses.push(...reverseText[color]);
        }
        if (buttonStyle === 'outline') {
            tailwindClasses.push(...borders[color]);
            tailwindClasses.push(...solidBackgrounds['transparent']);
            tailwindClasses.push(...coloredText[color]);
        }
        if (buttonStyle === 'decent') {
            const decentBackground = [...decentBackgrounds[color]];
            tailwindClasses.push(...borders['transparent']);
            tailwindClasses.push(...decentBackground);
            tailwindClasses.push(...coloredText['transparent']);
        }
        if (buttonStyle === 'text') {
            tailwindClasses.push(...borders['transparent']);
            tailwindClasses.push(...transparentBackgrounds[color]);
            tailwindClasses.push(...coloredText[color]);
        }

        // ring
        tailwindClasses.push(...focusRing[color]);

        // disabled opacity + remove active, focus and hover states

        if (disabled) {
            tailwindClasses.push('opacity-80', 'cursor-not-allowed');
            tailwindClasses = tailwindClasses.filter(
                (p) => !(p.includes('hover') || p.includes('active') || p.includes('focus'))
            );
        }

        return tailwindClasses.join(' ');
    });
}
